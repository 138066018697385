import { globalV } from "../configs/globalValues";

const { WITMEG_ORGSERVER___BASEURL, WITMEG_APPSERVER___BASEURL, REACT_APP__PAYMENTMODULESERVER___BASEURL, REACT_APP_DYNAMIC_REGISTRY___BASEURL, WITMEG_REPORTING_BASE_URL } = globalV.baseURLS;

const apiEndpointPaths = {
  // ******************************************* Org Server (Org Registry) *******************************************
  witmegOrgServer: {
    loginUser: `${WITMEG_ORGSERVER___BASEURL}/v1/controlcenter/login`, // ---> POST
    getFullUserOrgDetailsByUserId: `${WITMEG_ORGSERVER___BASEURL}/v1/controlcenter/userdata`, // ---> POST
    getUserDetailsByUserId: `${WITMEG_ORGSERVER___BASEURL}/user`, // ---> GET
    getPermissions: `${WITMEG_ORGSERVER___BASEURL}/v1/witmeg/scope/app`, // ---> POST
  },

  // *******************************************  Payment Module Server *******************************************
  witmegPaymentServer: {
    getFormDetailsByModuleID: `https://dynamicui.throbox.com/v1/module/form/get`, // ---> POST
    getFormDetailsByFormID: `https://dynamicui.throbox.com/v1/form/template/get`, // ---> POST
    getDialingCode: `https://dynamicui.throbox.com/v1/country/get`, // ---> POST
    getTermsAndConditions: `https://dynamicui.throbox.com/v1/terms/default/get`, // ---> GET
  },

  witmegReportingServer: {
    // **************** Reporting Related APIS ****************
    getReportListByAppIDAndOrgID: `${WITMEG_REPORTING_BASE_URL}/app-dashboards`, // ---> GET
  },
};

export default apiEndpointPaths;
