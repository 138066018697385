import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getFullUserOrgDetailsByUserId as getFullUserOrgDetailsByUserIdAPI, getUserDetailsByUserId } from "../utilities/apiRequests/witmegOrgServerRequests";
import { reqStatusTypes } from "../configs/constants";

export const fetchUserDetailsById = createAsyncThunk("Dashboard/fetchUserDetailsByIdStatus", async (data, thunkAPI) => {
  const response = await getFullUserOrgDetailsByUserIdAPI({
    //  OrganizationID: data.orgId,
    UserID: data.userId,
  });
  return response;
});

export const fetchUserDataById = createAsyncThunk("Dashboard/fetchUserDatasByIdStatus", async (userId, thunkAPI) => {
  const response = await getUserDetailsByUserId({ UserID: userId });
  return response;
});

export const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    // currentlySelectedOrgIndex: 0,
    // currentlySelectedCompanyIndex: 0,
    // currentlySelectedLocationIndex: 0,
    // fullUserOrgDetailsList: [],
    // getFullUserOrgDetailsByUserIdReqStatus: null,

    getFullUserOrgDetailsByUserIdReqStatus: reqStatusTypes.idle,
    getFullUserOrgDetailsByUserIdReqError: "",
    fullUserOrgDetailsList: [],

    getFullUserDetailsByUserIdReqStatus: reqStatusTypes.idle,
    getFullUserOrgDetailsByUserIdReqError: "",
    fullUserDetails: {},

    currentlySelectedOrgIndex: 0, // This track which index of above 'state.fullUserOrgDetailsList' array is currently selected.
    currentlySelectedCompanyIndex: 0,
    currentlySelectedLocationIndex: 0,

    currentlySelectedOrgId: "",
    currentlySelectedCompanyId: "",
    currentlySelectedLocationId: "",
    currentlySelectedLocationName: "",
    currentlySelectedOrgName: "",
  },
  reducers: {
    setLocationSelectorData: (state, action) => {
      const { orgIndex, orgId, comIndex, comId, locIndex, locId, locName, storesList, locDetails, orgName } = action.payload || {};

      const userString = localStorage.getItem("user");
      const user = JSON.parse(userString);
      user.OrganizationID = orgId;
      const jsonString = JSON.stringify(user);
      localStorage.setItem("user", jsonString);

      state.currentlySelectedOrgIndex = orgIndex; // This track which index of above 'state.fullUserOrgDetailsList' array is currently selected.
      state.currentlySelectedOrgId = orgId;
      state.currentlySelectedOrgName = orgName;

      state.currentlySelectedCompanyIndex = comIndex;
      state.currentlySelectedCompanyId = comId;

      state.currentlySelectedLocationIndex = locIndex;
      state.currentlySelectedLocationId = locId;
      state.currentlySelectedLocationName = locDetails.LocationName;
      state.storesList = storesList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetailsById.pending, (state, action) => {
      state.getFullUserOrgDetailsByUserIdReqStatus = "PENDING";
    });

    const visitedLocation = sessionStorage.getItem("lastVisitedLocation");
    let parsedLocation = null;
    if (visitedLocation) {
      parsedLocation = JSON.parse(visitedLocation);
    }

    builder.addCase(fetchUserDetailsById.fulfilled, (state, action) => {
      state.getFullUserOrgDetailsByUserIdReqStatus = "SUCCEEDED";
      const allOrganizationDetails = [];
      action?.payload?.Organization_LicenseData?.forEach((item) => {
        allOrganizationDetails.push(item);
      });

      state.fullUserOrgDetailsList = allOrganizationDetails;
      state.currentlySelectedLocationId = action.payload.Organization_LicenseData[0]?.Licenses[0]?.CloudLocationID[0];
      state.currentlySelectedLocationName = action.payload.Organization_LicenseData[0].Licenses[0].Location_Companies[0].LocationName;
      state.currentlySelectedOrgName = action.payload.OrgManagementData[0].OrganizationData.Name;

      state.currentlySelectedOrgId = action.payload.OrgManagementData[0].OrganizationID;

      const locationsRelatedToCurrentApps = action.payload.Organization_LicenseData.map((apps) => apps.Licenses.filter((app) => app.ApplicationID === "65796b3de64acc9fc7beadf8" || app.ApplicationID === "65796b3de64acc9fc7beadf8")).flat();

      const restructuredData = {};

      locationsRelatedToCurrentApps.forEach((item) => {
        const locationCompanies = item.Location_Companies;
        locationCompanies.forEach((location) => {
          const locationID = location.LocationID;
          restructuredData[locationID] = item;
        });
      });

      console.log("array1", restructuredData);
      // const restructuredData = {};
      // const locat = action.payload.Organization_LicenseData.forEach(item => {
      //     const licenses = item.Licenses;
      //     licenses.forEach(license => {
      //         const cloudLocationIDs = license.CloudLocationID;

      //         cloudLocationIDs.forEach(id => {
      //             [id] = item.Licenses;
      //         });
      //     });
      // });

      // const locations = action.payload.OrgManagementData[0].OrganizationData.Companies[0].Locations;
      const allowedLocations = restructuredData;
      // const availableLocations = locations
      //     .map((l) => {
      //         return allowedLocations.hasOwnProperty(l.LocationID) && l;
      //     })
      //     .filter((_i) => _i);

      const restructuredDataLocations = [];

      // locationsRelatedToCurrentApps.forEach(location => {
      //     const locationID = location.Location_Companies;
      //     locationID.forEach(locationCom => {
      //         restructuredDataLocations.push(locationCom)
      //     });
      // });

      locationsRelatedToCurrentApps.forEach((app) => {
        app.Location_Companies.forEach((location) => {
          const restructuredLocation = {
            LocationName: location.LocationName,
            CompanyName: location.CompanyName,
            LocationID: location.LocationID,
            CompanyID: location.CompanyID,
            OrganizationName: app.OrganizationName,
          };
          restructuredDataLocations.push(restructuredLocation);
        });
      });

      console.log("restructuredDataLocations", restructuredDataLocations);
      state.fullUserOrgDetailsList.availableLocations = restructuredDataLocations;
    });

    builder.addCase(fetchUserDetailsById.rejected, (state, action) => {
      state.getFullUserOrgDetailsByUserIdReqStatus = "FAILED";
    });

    builder.addCase(fetchUserDataById.pending, (state, action) => {
      state.getFullUserDetailsByUserIdReqStatus = "PENDING";
    });

    builder.addCase(fetchUserDataById.fulfilled, (state, action) => {
      state.getFullUserDetailsByUserIdReqStatus = "SUCCEEDED";
      state.fullUserDetails = action.payload;
    });

    builder.addCase(fetchUserDataById.rejected, (state, action) => {
      state.getFullUserDetailsByUserIdReqStatus = "FAILED";
    });
  },
});

export const { getFullUserOrgDetailsByUserId, setLocationSelectorData } = dashboardSlice.actions;
export default dashboardSlice.reducer;
