import React, { useState, useEffect, useRef } from "react";
import { Stack, StackItem, Button, Table, Space, InputGroup, SearchableMenu, RightPanelHeader, RightPanel, Accordion, Checkbox, Popover, ActionList, Icon, Alert, Typography, Popup, Steps, FileUploader, InlineMessage, Message, Label, DatePicker, Spin, ConfirmationPopup, Switch, Pagination, Select, InputBox, TextArea } from "witmeg-ui-system";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFormDetailsByModuleID, getFormDetailsByFormID, dynamicDataAccess, getDialingCode } from "../../utilities/apiRequests/witmegDynamicServerRegistry";
import OnboardingStatus from "../OnboardingStatus.js";

function CustomerOnboarding() {
  const location = useLocation();
  const navigate = useNavigate();
  const dashboardSliceState = useSelector((state) => state.dashboard);
  const [formID, setformID] = useState(location.state.WorkFlow.ModuleID);
  const [partnerCards, setPartnerCards] = useState([]);
  const [storeCards, setStoreCards] = useState([]);
  const [formData, setFormData] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);
  const [industryCodeOptions, setIndustryCodeOptions] = useState([]);
  const [storeTypeOptions, setStoreTypeOptions] = useState([]);
  const loadData = location.state.Result;
  const [basicInformationValues, setBasicInformationValues] = useState({
    orgName: loadData?.OrganizationName ? loadData?.OrganizationName : "",
  });

  const [proceedStatus, setProceedStatus] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [onboardingStatusData, setOnboardingStatusData] = useState();
  const [dialingCode, setDialingCode] = useState();
  const [missingBasicFieldNames, setMissingBasicFieldNames] = useState([]);
  const [validations, setValidations] = useState({
    entityOption: false,
    industryCodeOption: false,
    storeTypeOption: false,
    shopperStatement: false,
    phoneNumber: false,
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const [storeInformationValues, setStoreInformationValues] = useState({
    storeName: loadData?.Stores[0].StoreName ? loadData?.Stores[0].StoreName : "",
    countryCode: loadData?.Stores[0].CountryCode ? loadData?.Stores[0].CountryCode : "",
    line1: loadData?.Stores[0].Address.AddressLine1 ? loadData?.Stores[0].Address.AddressLine1 : "",
    line2: loadData?.Stores[0].Address.AddressLine2 ? loadData?.Stores[0].Address.AddressLine2 : "",
    line3: loadData?.Stores[0].Address.AddressLine3 ? loadData?.Stores[0].Address.AddressLine3 : "",
    city: loadData?.Stores[0].Address.City ? loadData?.Stores[0].Address.City : "",
    state: loadData?.Stores[0].Address.StateProvinceRegion ? loadData?.Stores[0].Address.StateProvinceRegion : "",
    postalCode: loadData?.Stores[0].Address.PostZipCode ? loadData?.Stores[0].Address.PostZipCode : "",
  });
  const [apiData, setApiData] = useState({
    entityType: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
    industrySelection: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
    storeType: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
    button: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
  });

  const getFormattedAPIData = (result) => {
    setFormData(result);
    const updatedEntityTypeData = {
      apiEndPoint: `${result.Widgets[0].Action.APIConfig.BasePath}${result.Widgets[0].Action.APIConfig.Route}`,
      method: `${result.Widgets[0].Action.APIConfig.APIMethod}`,
      authType: `${result.Widgets[0].Action.APIConfig.AuthType}`,
    };

    const updatedIndustrySelectionData = {
      apiEndPoint: `${result.Widgets[1].Action.APIConfig.BasePath}${result.Widgets[1].Action.APIConfig.Route}`,
      method: `${result.Widgets[1].Action.APIConfig.APIMethod}`,
      authType: `${result.Widgets[1].Action.APIConfig.AuthType}`,
    };

    const updatedStoreTypeData = {
      apiEndPoint: `${result.Widgets[2].Action.APIConfig.BasePath}${result.Widgets[2].Action.APIConfig.Route}`,
      method: `${result.Widgets[2].Action.APIConfig.APIMethod}`,
      authType: `${result.Widgets[2].Action.APIConfig.AuthType}`,
    };

    const updatedButtonData = {
      apiEndPoint: `${result.Widgets[3].Action.APIConfig.BasePath}${result.Widgets[3].Action.APIConfig.Route}`,
      method: `${result.Widgets[3].Action.APIConfig.APIMethod}`,
      authType: `${result.Widgets[3].Action.APIConfig.AuthType}`,
      actionType: `${result.Widgets[3].Action.ActionType}`,
      eventType: `${result.Widgets[3].Action.EventType}`,
      widgetID: `${result.Widgets[3].Id}`,
      apiID: `${result.Widgets[3].Action.APIConfig.APIID}`,
    };

    setApiData((prevState) => ({
      ...prevState,
      entityType: {
        ...updatedEntityTypeData,
      },
      industrySelection: {
        ...updatedIndustrySelectionData,
      },
      storeType: {
        ...updatedStoreTypeData,
      },
      button: {
        ...updatedButtonData,
      },
    }));
  };

  const getFormattedData = async (result) => {
    return await result.map((data, index) => {
      return {
        value: index,
        label: data,
      };
    });
  };

  const getFormattedIndustryCodeData = async (result) => {
    return await result.map((data, index) => {
      return {
        value: data.Industry_Code,
        label: data.Description,
      };
    });
  };

  const fetchDataEntity = async () => {
    const result = await dynamicDataAccess(
      {},
      {
        method: "POST",
        url: "https://payment.throbox.com/v1/p1/onboard/witmeg/entitytype/fetch",
      }
    );

    if (result.Result.length > 0) {
      const data = await getFormattedData(result.Result);
      setEntityOptions(data);
    }
  };

  const fetchDataIndustry = async () => {
    const result = await dynamicDataAccess(
      {},
      {
        method: "POST",
        url: "https://payment.throbox.com/v1/p1/onboard/witmeg/industrycode/fetch",
      }
    );
    if (result.Result.length > 0) {
      const data = await getFormattedIndustryCodeData(result.Result);
      setIndustryCodeOptions(data);
    }
  };

  const fetchDataStoreType = async () => {
    const result = await dynamicDataAccess(
      {},
      {
        method: "POST",
        url: "https://payment.throbox.com/v1/p1/onboard/witmeg/storetype/fetch",
      }
    );
    if (result.Result.length > 0) {
      const data = await getFormattedData(result.Result);
      setStoreTypeOptions(data);
    }
  };

  useEffect(() => {
    fetchDataEntity();
    fetchDataIndustry();
    fetchDataStoreType();
  }, []);

  useEffect(() => {
    if (loadData && loadData.Org_CountryCode) {
      const fetchPhoneCode = async () => {
        const result = await getDialingCode({
          CountryCode: loadData.Org_CountryCode,
        });
        if (result && Object.keys(result).length > 0) {
          setDialingCode(result.PhoneCode);
        }
      };
      fetchPhoneCode();
    }
  }, [loadData.Org_CountryCode]);

  const handleAddPartners = () => {
    const newPartner = {};
    setPartnerCards([...partnerCards, newPartner]);
  };

  const handleAddStores = () => {
    const newStore = {};
    setStoreCards([...storeCards, newStore]);
  };

  useEffect(() => {
    setPartnerCards(loadData.Partners);
    setStoreCards(loadData.Stores);
  }, [loadData]);

  useEffect(() => {
    if (dashboardSliceState && dashboardSliceState.currentlySelectedOrgId && formID !== "") {
      const fetchData = async () => {
        const result = await getFormDetailsByFormID({
          ApplicationID: process.env.REACT_APP_APPLICATION_ID,
          FormID: formID,
        });
        if (result && Object.keys(result).length > 0) {
          await getFormattedAPIData(result);
        }
      };
      fetchData();
    }
  }, [formID]);

  const handleChangeInput = (e, fieldName) => {
    let updatedBasicInformationValues = {
      ...basicInformationValues,
      [fieldName]: e.target.value,
    };
    setBasicInformationValues(updatedBasicInformationValues);
  };

  const handleChangeInputStore = (e, fieldName) => {
    let updatedStoreInformationValues = {
      ...storeInformationValues,
      [fieldName]: fieldName === "phoneNumber" ? e : e.target.value,
    };
    setStoreInformationValues(updatedStoreInformationValues);

    setValidations({
      ...validations,
      [fieldName]: updatedStoreInformationValues[fieldName] === "",
    });
    const missingBasicFields = [!updatedStoreInformationValues.shopperStatement ? "Shopper Statement" : null, !updatedStoreInformationValues.phoneNumber ? "Phone Number" : null].filter((x) => x);
    setMissingBasicFieldNames(missingBasicFields);
  };

  const handleChangeSelect = (e, fieldName) => {
    let updatedBasicInformationValues = {
      ...basicInformationValues,
      [fieldName]: e,
    };
    setBasicInformationValues(updatedBasicInformationValues);
    setValidations({
      ...validations,
      [fieldName]: updatedBasicInformationValues[fieldName] === "",
    });
    const missingBasicFields = [!updatedBasicInformationValues.entityOption ? " Main Entity Type" : null, !updatedBasicInformationValues.industryCodeOption ? "Industry Code" : null, !updatedBasicInformationValues.storeTypeOption ? "Required Store Types" : null].filter((x) => x);
    setMissingBasicFieldNames(missingBasicFields);
  };

  console.log("basicInformationValues", basicInformationValues);
  console.log("loadData", loadData);

  const handleSubmit = () => {
    setIsSubmit(true);
    let infoValidations = {
      entityOption: !basicInformationValues.entityOption,
      industryCodeOption: !basicInformationValues.industryCodeOption,
      storeTypeOption: !basicInformationValues.storeTypeOption,
      shopperStatement: !storeInformationValues.shopperStatement,
      phoneNumber: !storeInformationValues.phoneNumber,
    };

    setValidations(infoValidations);
    const missingBasicFields = [!basicInformationValues.entityOption ? "Main Entity Type" : null, !basicInformationValues.industryCodeOption ? "Industry Code" : null, !basicInformationValues.storeTypeOption ? "Required Store Types" : null, !storeInformationValues.shopperStatement ? "Shopper Statement" : null, !storeInformationValues.phoneNumber ? "Phone Number" : null].filter((x) => x);
    setMissingBasicFieldNames(missingBasicFields);

    if (missingBasicFields.length > 0) {
      setIsSubmit(false);
      const missingBasicInfoMessage = missingBasicFields.length > 0 ? `${missingBasicFields.join(", ")} field(s) are missing in Basic Information.` : "";
      Message.error(missingBasicInfoMessage);
    } else if (missingBasicFields.length === 0) {
      setIsSubmit(true);
    }

    if (apiData.button.apiEndPoint !== "" && apiData.button.method !== "" && apiData.button.authType !== "" && missingBasicFields.length === 0) {
      const storeArray = [];
      storeArray.push({
        StoreName: storeInformationValues.storeName,
        CloudLocationID: loadData.CloudLocationID,
        Description: storeInformationValues.description ? storeInformationValues.description : "",
        ShopperStatement: storeInformationValues.shopperStatement ? storeInformationValues.shopperStatement : "",
        // PhoneNumber: "+44 1632 960616",
        PhoneNumber: `${dialingCode}${storeInformationValues.phoneNumber}`,
        StoreType: basicInformationValues.storeTypeOption === undefined ? "" : basicInformationValues.storeTypeOption.label,
        Reference: loadData.CloudLocationID,
        Address: {
          // CountryCode: "GB",
          CountryCode: storeInformationValues.countryCode,
          Line1: storeInformationValues.line1,
          City: storeInformationValues.city,
          // PostalCode: "W13 9AE",
          Sate: storeInformationValues.state,
          PostalCode: storeInformationValues.postalCode,
        },
      });

      const partnerArray = partnerCards.map((part) => {
        return {
          FirstName: part.FirstName,
          LastName: part.LastName,
          CountryCode: part.CountryCode,
        };
      });

      const arrayStoreTypes = [];
      arrayStoreTypes.push(basicInformationValues.storeTypeOption ? basicInformationValues.storeTypeOption.label : "");

      const arrayWebsiteUrl = [];
      arrayWebsiteUrl.push(basicInformationValues.websiteUrl ? basicInformationValues.websiteUrl : "");

      const fetchData = async () => {
        const result = await dynamicDataAccess(
          {
            ID: "",
            FormSubmissionNo: "",
            TemplateFormDetails: {
              ID: formData.TemplateID,
              ApplicationID: formData.ApplicationID,
              FormID: formData.FormID,
              VersionNo: formData.Version,
              ActionType: apiData.button.actionType,
              EventType: apiData.button.eventType,
              WidgetID: apiData.button.widgetID,
              APIID: apiData.button.apiID,
            },
            PayLoad: [
              {
                Key: "OrganizationID",
                Value: loadData.OrganizationID,
                Type: "STRING",
              },
              {
                Key: "MainEntityType",
                Value: basicInformationValues.entityOption ? basicInformationValues.entityOption.label : "",
                Type: "STRING",
              },
              {
                Key: "MainEntity_Name",
                Value: basicInformationValues.orgName,
                Type: "STRING",
              },
              {
                Key: "MainEntity_CountryCode",
                Value: loadData.Org_CountryCode,
                Type: "STRING",
              },
              {
                Key: "MainEntity_City",
                Value: loadData.Address.City ? loadData.Address.City : "",
                Type: "STRING",
              },
              {
                Key: "MainEntity_PostalCode",
                Value: loadData.Address.PostZipCode,
                Type: "STRING",
              },
              // {
              //     "Key": "MainEntity_PostalCode",
              //     "Value": "W13 9AE",
              //     "Type": "STRING"
              // },
              {
                Key: "MainEntity_Street",
                Value: loadData.Address.AddressLine1,
                Type: "STRING",
              },
              {
                Key: "MainEntity_SalesChannels",
                Value: JSON.stringify(arrayStoreTypes),
                Type: "ARRAY",
              },
              {
                Key: "MainEntity_IndustryCode",
                Value: basicInformationValues.industryCodeOption ? basicInformationValues.industryCodeOption.value : "",
                Type: "STRING",
              },
              {
                Key: "WebSiteURL",
                Value: JSON.stringify(arrayWebsiteUrl),
                Type: "ARRAY",
              },
              // {
              //     "Key": "Individual_Entities",
              //     "Value": "[{\"FirstName\": \"Winston\", \"LastName\": \"David\", \"CountryCode\": \"GB\"}]",
              //     "Type": "ARRAY"
              // },
              {
                Key: "Individual_Entities",
                Value: JSON.stringify(partnerArray),
                Type: "ARRAY",
              },
              {
                Key: "Store",
                Value: JSON.stringify(storeArray),
                Type: "ARRAY",
              },
              {
                Key: "CloudLocationID",
                Value: loadData.CloudLocationID,
                Type: "STRING",
              },
            ],
            SubmittedBy: formData.CreatedBy,
            CreatedDate: formData.CreatedDate,
            ModifiedDate: formData.ModifiedDate,
            IsDeleted: false,
          },
          {
            method: apiData.button.method,
            url: apiData.button.apiEndPoint,
          }
        );
        // const result = {
        //   ProcessStages: [
        //     {
        //       Error: "",
        //       Process: "Main_Legal_Entity_Creation",
        //       Result: "LE322JV223224V5K8MZGN688H",
        //     },
        //     {
        //       Error: "",
        //       Process: "Individual_Entity_Creation",
        //       Result: ["LE322JV223224V5K8MZGP68CB"],
        //     },
        //     {
        //       Error: "",
        //       Process: "Entity_Association_Stage",
        //       Result:
        //         '{"entityAssociations":[{"associatorId":"LE322JV223224V5K8MZGN688H","entityType":"individual","legalEntityId":"LE322JV223224V5K8MZGP68CB","name":"Winston David","type":"uboThroughOwnership"},{"associatorId":"LE322JV223224V5K8MZGN688H","entityType":"individual","jobTitle":"CEO","legalEntityId":"LE322JV223224V5K8MZGP68CB","name":"Winston David","type":"uboThroughControl"},{"associatorId":"LE322JV223224V5K8MZGN688H","entityType":"individual","jobTitle":"Director","legalEntityId":"LE322JV223224V5K8MZGP68CB","name":"Winston David","type":"signatory"}],"organization":{"legalName":"winston12","registeredAddress":{"country":"GB"}},"type":"organization","id":"LE322JV223224V5K8MZGN688H"}',
        //     },
        //     {
        //       Error: "",
        //       Process: "Account_Holder_Stage",
        //       Result: "AH3222Z223226K5K8MZGP56HH",
        //     },
        //     {
        //       Error: "",
        //       Process: "BusinessLine_Stage",
        //       Result: "SE322JV223224V5K8MZGQ68HT",
        //     },
        //     {
        //       Error: "",
        //       Process: "Store_Creation_Stage",
        //       Result: ["ST3224X22322655K8MZGQ78CB"],
        //     },
        //     {
        //       Error: "",
        //       Process: "Hosted_OnboardingURL_Creation_Stage",
        //       Result:
        //         "https://balanceplatform-test.adyen.com/balanceplatform/uo/form/xtl-AQE1wiSYKsacOkkT7Wm1omg5s-KUWoZRUpttEzx8lUap2CJyhZBJCXBA3ZT45m9oBRM6bxshnfwQwV1bDb7kfNy1WIxIIkxgBw/S3B-OGAnbnk7ODFmS0xkO2tJTnA33LWApWXViKU0tX32E6SWAyeExBYiFNXmpyXFlJITU4d0o34aG5GUjNYMjZbPSAuNyhFWXZ7bDI33f0ZaMychfW06SEMsIihgTW8iZGo4?signature=QrZG3YPrbGE3T1ySAU7qCB7eEGmwHD4sH18pCmgGhdg&cd=1707909080653&style=S3B-RT5sVmBOWWQlcDgxfn1dIk1tdXE0P0YsX1ZcMD5ybWQkWDdxLCtyXnB6eCtuNUJ4OF19eDloKSI&redirectUrl=https://ecologital.com&expiry=1707909320653",
        //     },
        //   ],
        //   Result:
        //     "https://balanceplatform-test.adyen.com/balanceplatform/uo/form/xtl-AQE1wiSYKsacOkkT7Wm1omg5s-KUWoZRUpttEzx8lUap2CJyhZBJCXBA3ZT45m9oBRM6bxshnfwQwV1bDb7kfNy1WIxIIkxgBw/S3B-OGAnbnk7ODFmS0xkO2tJTnA33LWApWXViKU0tX32E6SWAyeExBYiFNXmpyXFlJITU4d0o34aG5GUjNYMjZbPSAuNyhFWXZ7bDI33f0ZaMychfW06SEMsIihgTW8iZGo4?signature=QrZG3YPrbGE3T1ySAU7qCB7eEGmwHD4sH18pCmgGhdg&cd=1707909080653&style=S3B-RT5sVmBOWWQlcDgxfn1dIk1tdXE0P0YsX1ZcMD5ybWQkWDdxLCtyXnB6eCtuNUJ4OF19eDloKSI&redirectUrl=https://ecologital.com&expiry=1707909320653",
        //   Status: true,
        //   WorkFlow: {
        //     HyperLink: "/paymentOnboarding/view-Application",
        //     TemplateID: "general-template-success",
        //     ModuleID: "c9777575-b7a4-46e9-a1c8-cdff740cc357",
        //   },
        // };

        if (Object.keys(result).length > 0) {
          if (result.ProcessStages && result.Status) {
            setShowStatusPopup(true);
            setOnboardingStatusData(result);
            Message.success("Onboarded Successfully.");
            setProceedStatus(true);
          } else if (result.ProcessStages) {
            setShowStatusPopup(true);
            setOnboardingStatusData(result);
            setProceedStatus(false);
          } else {
            Message.error(result.Result);
            setShowStatusPopup(false);
            navigate(`${result.WorkFlow.HyperLink}`);
          }
        }
      };
      fetchData();
    }
  };

  console.log("validations", validations);

  return (
    <div className="customer-page-content" style={{ padding: "20px" }}>
      <Popup
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ fontSize: "16px" }}>Status: {loadData.OnboardingStatus}</div>
          </div>
        }
        centered
        open={showStatusPopup}
        onCancel={() => setShowStatusPopup(false)}
        closable={true}
        width={397}
      >
        <OnboardingStatus loadData={loadData} onboardingStatusData={onboardingStatusData} proceedStatus={proceedStatus} setShowStatusPopup={setShowStatusPopup} />
      </Popup>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0px 30px 0px" }}>
        <Typography style={{ fontSize: "20px" }} pattern="bold">
          Customer Onboarding
        </Typography>
        <div style={{ display: "flex", width: "300px", justifyContent: "flex-end" }}>
          <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
            <Typography>Profile Status : </Typography>
          </div>
          <div style={{ width: "40%", display: "flex", justifyContent: "center" }}>
            <Typography style={{ color: "#C34A4A" }}>{loadData.OnboardingStatus}</Typography>
          </div>
        </div>
      </div>
      <div className="customer-page-header" style={{ paddingBottom: "20px", display: "flex", justifyContent: "space-evenly", border: "1px solid #F2F2F2", height: "1500vh" }}>
        <div style={{ paddingBottom: "20px", flexDirection: "column", justifyContent: "flex-start", width: "50%", margin: "30px  0px 30px 20px" }}>
          <div>
            <Typography style={{ fontSize: "20px" }}>Customer Details</Typography>

            <div style={{ display: "flex", margin: "30px 0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>OrganizationID </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{loadData.OrganizationID}</Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Organization Name </Label>
              </div>
              <div style={{ width: "50%" }}>
                <InputBox type="text" value={basicInformationValues.orgName} onChange={(e) => handleChangeInput(e, "orgName")} />
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Country Code </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{loadData.Org_CountryCode}</Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Address </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{`${loadData.Address.AddressLine1}, ${loadData.Address.AddressLine2},  ${loadData.Address.Country}, ${loadData.Address.PostZipCode}`} </Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label required={true}>Main Entity Type </Label>
              </div>
              <div style={{ width: "70%" }}>
                <Select value={basicInformationValues.entityOption} options={entityOptions} onChange={(e) => handleChangeSelect(e, "entityOption")} hasError={validations.entityOption} />
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label required={true}>Industry Code </Label>
              </div>
              <div style={{ width: "70%" }}>
                <Select value={basicInformationValues.industryCodeOption} options={industryCodeOptions} onChange={(e) => handleChangeSelect(e, "industryCodeOption")} hasError={validations.industryCodeOption} />
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label required={true}>Required Store Types </Label>
              </div>
              <div style={{ width: "70%" }}>
                <Select value={basicInformationValues.storeTypeOption} options={storeTypeOptions} onChange={(e) => handleChangeSelect(e, "storeTypeOption")} hasError={validations.storeTypeOption} />
              </div>
            </div>
            {basicInformationValues.storeTypeOption && basicInformationValues.storeTypeOption.label === "ecom" ? (
              <div style={{ display: "flex", margin: "30px 0px 30px 0px", alignItems: "center" }}>
                <div style={{ width: "25%" }}>
                  <Label>Website URL </Label>
                </div>
                <div style={{ width: "70%" }}>
                  <InputBox type="text" value={storeInformationValues.websiteUrl} onChange={(e) => handleChangeInput(e, "websiteUrl")} />
                </div>
              </div>
            ) : null}
          </div>

          <div style={{ borderTop: " 5px solid var(--black-95, #F2F2F2) " }}>
            <Typography style={{ fontSize: "20px", marginTop: "40px" }}>Store Detail</Typography>

            <div style={{ display: "flex", margin: "30px 0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Store Name </Label>
              </div>
              <div style={{ width: "30%" }}>
                <InputBox type="text" value={storeInformationValues.storeName} onChange={(e) => handleChangeInputStore(e, "storeName")} />
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Cloud Location </Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData.CloudLocationName} </Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Description </Label>
              </div>
              <div style={{ width: "50%" }}>
                <TextArea type="text" placeholder="Add Description" value={storeInformationValues.description} onChange={(e) => handleChangeInputStore(e, "description")} />
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label required={true}>Shopper Statement </Label>
              </div>
              <div style={{ width: "50%" }}>
                <InputBox type="text" placeholder="Add name in bank statement" value={storeInformationValues.shopperStatement} onChange={(e) => handleChangeInputStore(e, "shopperStatement")} error={validations.shopperStatement} />
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label required={true}>Phone Number </Label>
              </div>
              <div style={validations.phoneNumber ? { backgroundColor: "#faebeb", borderColor: "#c33" } : { width: "50%" }}>
                <InputGroup leftComponent="text" leftDisabled={true} leftValue={dialingCode} rightWidth="20%" rightComponent="text" rightValue={storeInformationValues.phoneNumber} onChangeRightValue={(e) => handleChangeInputStore(e, "phoneNumber")} leftWidth="80%" />
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Reference </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{loadData.RefID} </Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px  0px 20px 0px", alignItems: "flex-start" }}>
              <div style={{ width: "25%" }}>
                <Label>Address </Label>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Linel </Label>
              </div>
              <div style={{ width: "30%" }}>
                <InputBox type="text" placeholder="Line1" value={storeInformationValues.line1} onChange={(e) => handleChangeInputStore(e, "line1")} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Line2 </Label>
              </div>
              <div style={{ width: "30%" }}>
                <InputBox type="text" placeholder="Line2" value={storeInformationValues.line2} onChange={(e) => handleChangeInputStore(e, "line2")} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Line3 </Label>
              </div>
              <div style={{ width: "30%" }}>
                <InputBox type="text" placeholder="Line3" value={storeInformationValues.line3} onChange={(e) => handleChangeInputStore(e, "line3")} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>City </Label>
              </div>
              <div style={{ width: "30%" }}>
                <InputBox type="text" placeholder="City" value={storeInformationValues.city} onChange={(e) => handleChangeInputStore(e, "city")} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>State</Label>
              </div>
              <div style={{ width: "30%" }}>
                <InputBox type="text" placeholder="State" value={storeInformationValues.state} onChange={(e) => handleChangeInputStore(e, "state")} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>PostalCode </Label>
              </div>
              <div style={{ width: "30%" }}>
                <InputBox type="text" value={storeInformationValues.PostZipCode} onChange={(e) => handleChangeInput(e, "websiteUrl")} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Country Code </Label>
              </div>
              <div style={{ width: "30%" }}>
                <InputBox type="text" disabled={true} placeholder="Country Code" value={storeInformationValues.countryCode} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "60px", display: "flex", alignSelf: "flex-end " }}>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "50%", margin: "30px  0px 30px 20px", borderLeft: "1px solid var(--black-95, #F2F2F2)" }}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between", marginRight: "100px" }}>
              <Typography style={{ fontSize: "20px", margin: "0px  0px 0px 20px" }}>Partner(s)</Typography>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon size={16} name="plus" color="blue" />
                </div>
                <Button variant="link" onClick={handleAddPartners}>
                  Add Partners
                </Button>
              </div>
            </div>
            {partnerCards.map((partners) => {
              return (
                <div style={{ display: "flex", flexDirection: "column", backgroundColor: "rgba(217, 217, 217, 0.15)", borderRadius: "8px", height: "190px", width: "80%", margin: "30px  0px 0px 20px" }}>
                  <div style={{ display: "flex", margin: "30px 0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>First Name : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{partners.FirstName} </Label>
                    </div>
                  </div>
                  <div style={{ display: "flex", margin: "30px  0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>Last Name : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{partners.LastName} </Label>
                    </div>
                  </div>
                  <div style={{ display: "flex", margin: "30px  0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>Address : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{`${partners.Address.AddressLine1}, ${partners.Address.AddressLine2}, ${partners.Address.City}, ${partners.Address.Country}, ${partners.Address.PostZipCode}`}</Label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: "50px", borderTop: " 1px solid #DFDFDF" }}>
            <div style={{ display: "flex", justifyContent: "space-between", margin: " 50px 100px 0px 0px" }}>
              <Typography style={{ fontSize: "20px", margin: "0px  0px 0px 20px" }}>Stores(s)</Typography>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon size={16} name="plus" color="blue" />
                </div>
                <Button variant="link" onClick={handleAddStores}>
                  Add Stores
                </Button>
              </div>
            </div>
            {storeCards.map((stores) => {
              return (
                <div style={{ display: "flex", flexDirection: "column", backgroundColor: "rgba(217, 217, 217, 0.15)", borderRadius: "8px", height: "130px", width: "80%", margin: "30px  0px 0px 20px" }}>
                  <div style={{ display: "flex", margin: "30px 0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>Store Name : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{storeInformationValues.storeName} </Label>
                    </div>
                  </div>
                  <div style={{ display: "flex", margin: "30px  0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>Address : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{`${storeInformationValues.line1}, ${storeInformationValues.line2}, ${storeInformationValues.line3}, ${storeInformationValues.city}, ${storeInformationValues.countryCode}, ${storeInformationValues.po}`}</Label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerOnboarding;
