import React, { useState, useEffect, useRef } from "react";
import { Stack, StackItem, Button, Table, Space, InputGroup, SearchableMenu, RightPanelHeader, RightPanel, Accordion, Checkbox, Popover, ActionList, Icon, Alert, Typography, Popup, Steps, FileUploader, InlineMessage, Message, Label, DatePicker, Spin, ConfirmationPopup, Switch, Pagination, Select, InputBox, TextArea } from "witmeg-ui-system";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFormDetailsByModuleID, getFormDetailsByFormID, dynamicDataAccess, getDialingCode } from "../../utilities/apiRequests/witmegDynamicServerRegistry.js";
import OnboardingStatus from "../OnboardingStatus.js/index.js";

function View() {
  const navigate = useNavigate();
  const location = useLocation();
  const loadData = location.state.Result;
  const dashboardSliceState = useSelector((state) => state.dashboard);
  const [formID, setformID] = useState(location?.state?.WorkFlow?.ModuleID);
  const [partnerCards, setPartnerCards] = useState(loadData.Partners);
  const [storeCards, setStoreCards] = useState(loadData.Stores);
  const [formData, setFormData] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);
  const [industryCodeOptions, setIndustryCodeOptions] = useState([]);
  const [storeTypeOptions, setStoreTypeOptions] = useState([]);
  const [basicInformationValues, setBasicInformationValues] = useState({
    orgName: loadData?.OrganizationName ? loadData?.OrganizationName : "",
  });
  const [showOopsPopUp, setShowOopsPopUp] = useState(loadData.OnboardingStatus === "ActionRequired" ? true : false);
  const [proceedStatus, setProceedStatus] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [onboardingStatusData, setOnboardingStatusData] = useState();
  const [validations, setValidations] = useState({
    entityOption: false,
    industryCodeOption: false,
    storeTypeOption: false,
    shopperStatement: false,
    phoneNumber: false,
  });

  const [storeInformationValues, setStoreInformationValues] = useState({
    storeName: loadData?.Stores[0].StoreName ? loadData?.Stores[0].StoreName : "",
    countryCode: loadData?.Stores[0].CountryCode ? loadData?.Stores[0].CountryCode : "",
    line1: loadData?.Stores[0].Address.AddressLine1 ? loadData?.Stores[0].Address.AddressLine1 : "",
    line2: loadData?.Stores[0].Address.AddressLine2 ? loadData?.Stores[0].Address.AddressLine2 : "",
    line3: loadData?.Stores[0].Address.AddressLine3 ? loadData?.Stores[0].Address.AddressLine3 : "",
    city: loadData?.Stores[0].Address.City ? loadData?.Stores[0].Address.City : "",
    state: loadData?.Stores[0].Address.StateProvinceRegion ? loadData?.Stores[0].Address.StateProvinceRegion : "",
    postalCode: loadData?.Stores[0].Address.PostZipCode ? loadData?.Stores[0].Address.PostZipCode : "",
  });
  const [apiData, setApiData] = useState({
    popUpHeading: {
      description: "",
    },
    popUpDescription: {
      description: "",
    },
    buttonOne: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
    buttonTwo: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
  });

  const getFormattedAPIData = (result) => {
    setFormData(result);

    const updatedDescriptionHeading = {
      description: `${result.Widgets[0].Text}`,
    };

    const updatedDescription = {
      description: `${result.Widgets[1].Text}`,
    };

    const updatedButtonOne = {
      displayName: result.Widgets[3].DisplayName,
      apiEndPoint: `${result.Widgets[3].Action.APIConfig.BasePath}${result.Widgets[3].Action.APIConfig.Route}`,
      method: `${result.Widgets[3].Action.APIConfig.APIMethod}`,
      authType: `${result.Widgets[3].Action.APIConfig.AuthType}`,
      hyperLink: result.Widgets[3].Action.Navigation.HyperLink,
    };

    const updatedButtonTwo = {
      displayName: result.Widgets[2].DisplayName,
      apiEndPoint: `${result.Widgets[2].Action.APIConfig.BasePath}${result.Widgets[2].Action.APIConfig.Route}`,
      method: `${result.Widgets[2].Action.APIConfig.APIMethod}`,
      authType: `${result.Widgets[2].Action.APIConfig.AuthType}`,
      actionType: `${result.Widgets[2].Action.ActionType}`,
      eventType: `${result.Widgets[2].Action.EventType}`,
      widgetID: `${result.Widgets[2].Id}`,
      apiID: `${result.Widgets[2].Action.APIConfig.APIID}`,
    };

    setApiData((prevState) => ({
      ...prevState,
      popUpHeading: {
        ...updatedDescriptionHeading,
      },
      popUpDescription: {
        ...updatedDescription,
      },
      buttonOne: {
        ...updatedButtonOne,
      },
      buttonTwo: {
        ...updatedButtonTwo,
      },
    }));
  };

  useEffect(() => {
    if (dashboardSliceState && dashboardSliceState.currentlySelectedOrgId && formID !== "" && loadData.OnboardingStatus === "ActionRequired") {
      const fetchData = async () => {
        const result = await getFormDetailsByFormID({
          ApplicationID: process.env.REACT_APP_APPLICATION_ID,
          FormID: formID,
        });
        if (result && Object.keys(result).length > 0) {
          await getFormattedAPIData(result);
        }
      };
      fetchData();
    }
  }, [formID]);

  console.log("loadData", loadData);

  const handleSecondButtonClick = async () => {
    setShowOopsPopUp(false);
    const result = await dynamicDataAccess(
      {
        ID: "",
        FormSubmissionNo: "",
        TemplateFormDetails: {
          ID: formData.TemplateID,
          ApplicationID: formData.ApplicationID,
          FormID: formData.FormID,
          VersionNo: formData.Version,
          ActionType: apiData.buttonTwo.actionType,
          EventType: apiData.buttonTwo.eventType,
          WidgetID: apiData.buttonTwo.widgetID,
          APIID: apiData.buttonTwo.apiID,
        },
        PayLoad: [
          {
            Key: "Main_Legal_EntityID",
            Value: loadData.Main_Legal_EntityID,
            Type: "STRING",
          },
        ],
        SubmittedBy: formData.CreatedBy,
        CreatedDate: formData.CreatedDate,
        ModifiedDate: formData.ModifiedDate,
        IsDeleted: false,
      },
      {
        method: apiData.buttonTwo.method,
        url: apiData.buttonTwo.apiEndPoint,
      }
    );

    if (result && result.OnBoardingURL) {
      window.open(result.OnBoardingURL, "_blank", "noopener,noreferrer");
      navigate(`${result.WorkFlow.HyperLink}`);
    } else {
      navigate(`${result.WorkFlow.HyperLink}`);
    }
  };

  console.log("apiData", apiData);
  console.log("loadData", loadData);

  return (
    <div className="customer-page-content" style={{ padding: "20px" }}>
      <Popup
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ fontSize: "16px" }}>{apiData.popUpHeading.description}</div>
          </div>
        }
        centered
        open={showOopsPopUp}
        onCancel={() => setShowOopsPopUp(false)}
        closable={true}
        width={560}
      >
        <div>
          {apiData.popUpDescription.description}
          <div style={{ display: "flex", margin: "20px 50px 0px 0px ", width: "100%", justifyContent: "flex-end" }}>
            <div style={{ marginRight: "10px" }}>
              <Button
                onClick={() => {
                  setShowOopsPopUp(false);
                  navigate(apiData.buttonOne.hyperLink, { state: location.state });
                }}
                variant="secondary"
              >
                {apiData.buttonOne.displayName}
              </Button>
            </div>
            <div>
              <Button variant="primary" onClick={handleSecondButtonClick}>
                {apiData.buttonTwo.displayName}
              </Button>
            </div>
          </div>
        </div>
      </Popup>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0px 30px 0px" }}>
        <Typography style={{ fontSize: "20px" }} pattern="bold">
          Customer Onboarding
        </Typography>
        <div style={{ display: "flex", width: "300px", justifyContent: "flex-end" }}>
          <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
            <Typography>Profile Status : </Typography>
          </div>
          <div style={{ width: "40%", display: "flex", justifyContent: "center" }}>
            <Typography style={{ color: "#C34A4A" }}>{loadData.OnboardingStatus}</Typography>
          </div>
        </div>
      </div>
      <div className="customer-page-header" style={{ paddingBottom: "20px", display: "flex", justifyContent: "space-evenly", border: "1px solid #F2F2F2", height: "1500vh" }}>
        <div style={{ paddingBottom: "20px", flexDirection: "column", justifyContent: "flex-start", width: "50%", margin: "30px  0px 30px 20px" }}>
          <div>
            <Typography style={{ fontSize: "20px" }}>Customer Details</Typography>

            <div style={{ display: "flex", margin: "30px 0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>OrganizationID </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{loadData.OrganizationID}</Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Organization Name </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{loadData.OrganizationName}</Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Country Code </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{loadData.Org_CountryCode}</Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Address </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{`${loadData.Address.AddressLine1}, ${loadData.Address.AddressLine2},  ${loadData.Address.Country}, ${loadData.Address.PostZipCode}`} </Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Main Entity Type </Label>
              </div>
              <div style={{ width: "70%" }}>
                <Label>{loadData.EntityType}</Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Industry Code </Label>
              </div>
              <div style={{ width: "70%" }}>
                <Label>{loadData.IndustryCode}</Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Required Store Types </Label>
              </div>
              <div style={{ width: "70%" }}>
                <Label>{loadData.StoreTypes}</Label>
              </div>
            </div>
          </div>
          <div style={{ borderTop: " 5px solid var(--black-95, #F2F2F2) " }}>
            <Typography style={{ fontSize: "20px", marginTop: "40px" }}>Store Detail</Typography>
            <div style={{ display: "flex", margin: "30px 0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Store Name </Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData?.Stores[0].StoreName}</Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Cloud Location </Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData.CloudLocationName} </Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Description </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{loadData?.Stores[0].Description} </Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Shopper Statement </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{loadData?.Stores[0].ShopperStatement} </Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Phone Number </Label>
              </div>
              <div style={validations.phoneNumber ? { backgroundColor: "#faebeb", borderColor: "#c33" } : { width: "50%" }}>
                <Label>{loadData?.Stores[0].PhoneNumber}</Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 30px 0px", alignItems: "center" }}>
              <div style={{ width: "25%" }}>
                <Label>Reference </Label>
              </div>
              <div style={{ width: "50%" }}>
                <Label>{loadData.RefID} </Label>
              </div>
            </div>
            <div style={{ display: "flex", margin: "30px  0px 10px 0px", alignItems: "flex-start" }}>
              <div style={{ width: "25%" }}>
                <Label>Address </Label>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", margin: "20px  0px 10px 0px" }}>
              <div style={{ width: "25%" }}>
                <Label>Linel </Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData?.Stores[0].Address.AddressLine1}</Label>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", margin: "30px  0px 10px 0px" }}>
              <div style={{ width: "25%" }}>
                <Label>Line2 </Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData?.Stores[0].Address.AddressLine2}</Label>{" "}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", margin: "30px  0px 10px 0px" }}>
              <div style={{ width: "25%" }}>
                <Label>Line 3</Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData?.Stores[0].Address.AddressLine3}</Label>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", margin: "30px  0px 10px 0px" }}>
              <div style={{ width: "25%" }}>
                <Label>City </Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData?.Stores[0].Address.City}</Label>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", margin: "30px  0px 10px 0px" }}>
              <div style={{ width: "25%" }}>
                <Label>State</Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData?.Stores[0].Address.StateProvinceRegion}</Label>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", margin: "30px  0px 10px 0px" }}>
              <div style={{ width: "25%" }}>
                <Label>PostalCode </Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData?.Stores[0].Address.PostZipCode}</Label>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", margin: "30px  0px 10px 0px" }}>
              <div style={{ width: "25%" }}>
                <Label>Country Code </Label>
              </div>
              <div style={{ width: "30%" }}>
                <Label>{loadData?.Stores[0].CountryCode}</Label>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "50%", margin: "30px  0px 30px 20px", borderLeft: "1px solid var(--black-95, #F2F2F2)" }}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between", marginRight: "100px" }}>
              <Typography style={{ fontSize: "20px", margin: "0px  0px 0px 20px" }}>Partner(s)</Typography>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon size={16} name="plus" color="blue" />
                </div>
              </div>
            </div>
            {partnerCards.map((partners) => {
              return (
                <div style={{ display: "flex", flexDirection: "column", backgroundColor: "rgba(217, 217, 217, 0.15)", borderRadius: "8px", height: "190px", width: "80%", margin: "30px  0px 0px 20px" }}>
                  <div style={{ display: "flex", margin: "30px 0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>First Name : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{partners.FirstName} </Label>
                    </div>
                  </div>
                  <div style={{ display: "flex", margin: "30px  0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>Last Name : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{partners.LastName} </Label>
                    </div>
                  </div>
                  <div style={{ display: "flex", margin: "30px  0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>Address : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{`${partners.Address.AddressLine1}, ${partners.Address.AddressLine2}, ${partners.Address.City}, ${partners.Address.Country}, ${partners.Address.PostZipCode}`}</Label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: "50px", borderTop: " 1px solid #DFDFDF" }}>
            <div style={{ display: "flex", justifyContent: "space-between", margin: " 50px 100px 0px 0px" }}>
              <Typography style={{ fontSize: "20px", margin: "0px  0px 0px 20px" }}>Stores(s)</Typography>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon size={16} name="plus" color="blue" />
                </div>
              </div>
            </div>
            {storeCards.map((stores) => {
              return (
                <div style={{ display: "flex", flexDirection: "column", backgroundColor: "rgba(217, 217, 217, 0.15)", borderRadius: "8px", height: "130px", width: "80%", margin: "30px  0px 0px 20px" }}>
                  <div style={{ display: "flex", margin: "30px 0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>Store Name : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{storeInformationValues.storeName} </Label>
                    </div>
                  </div>
                  <div style={{ display: "flex", margin: "30px  0px 5px 30px", alignItems: "center" }}>
                    <div style={{ width: "25%" }}>
                      <Label>Address : </Label>
                    </div>
                    <div style={{ width: "50%", display: "flex", flex: "wrap" }}>
                      <Label>{`${storeInformationValues.line1}, ${storeInformationValues.line2}, ${storeInformationValues.line3}, ${storeInformationValues.city}, ${storeInformationValues.countryCode}, ${storeInformationValues.postalCode}`}</Label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default View;
