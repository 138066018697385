import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { generateToken } from '../utilities/apiRequests/tokenGenerators';
import { reqStatusTypes } from '../configs/constants';

export const fetchTokenDetails = createAsyncThunk(
    'tokenSlice/getTokenSlice',
    async (dispatch, getState) => {
        const response = await generateToken(false);
        // console.log("response", response)
        return response
    }
)


export const tokenSlice = createSlice(
    {
        name: "tokenSlice",
        initialState:
        {

            getFullUserOrgDetailsByUserIdReqStatus: reqStatusTypes.idle,
            defaultTokenState: {}
        },

        reducers: {

            setTokenState: (state, action) => {
                const { userToken } = action.payload || {};
                state.defaultTokenState = userToken;

            }
        },

        extraReducers: (builder) => {
            builder.addCase(fetchTokenDetails.pending, (state, action) => {
                state.getFullUserOrgDetailsByUserIdReqStatus = 'PENDING'
            })

            builder.addCase(fetchTokenDetails.fulfilled, (state, action) => {
                state.getFullUserOrgDetailsByUserIdReqStatus = 'SUCCEEDED'
                state.defaultTokenState = action.payload
            })

            builder.addCase(fetchTokenDetails.rejected, (state, action) => {
                state.getFullUserOrgDetailsByUserIdReqStatus = 'FAILED'
            })
        }
    });


export const { setTokenState } = tokenSlice.actions;
export default tokenSlice.reducer;