//import browserStorage from "store2";
import { routePaths } from "../routes/routeConstants";
import * as jose from "jose";
import { useNavigate } from "react-router-dom";
import navigatePage from "../utilities/navigatePage";

export function checkIsUserLoggedIn() {
  const existingUserValueInStorage = getLoggedUserDetails();

  if (existingUserValueInStorage.AccessToken) {
    return true;
  }
  return false;
}

export function getLoggedUserDetails() {
  const userString = localStorage.getItem("user");
  const user = JSON.parse(userString);
  if (user) {
    return user;
  }
  logoutUser();
  return {};
}

export function getLoggedUserId() {
  const loggedUserDetails = getLoggedUserDetails();
  return loggedUserDetails.userid;
}

export function logoutUser(options = {}) {
  const {
    redirectTo = routePaths.loginRoot, // By default re-direct to here.
    navigate = navigatePage,
    preventRedirect = false,
  } = options;

  localStorage.clear();
  localStorage.setItem("user", JSON.stringify({}));

  if (!preventRedirect && navigatePage && navigatePage.push) {
    navigatePage.push(redirectTo);
  }

  if (!preventRedirect) {
    navigatePage.push(redirectTo);
  }

  // return !preventRedirect ? <Navigate to={redirectTo} push /> : null;
}

export function saveLoggedUser(passedUserDetails = {}) {
  try {
    const { AccessToken } = passedUserDetails;

    if (!AccessToken) {
      return;
    }
    const existingUserValuesInStorage = getLoggedUserDetails();
    // Getting encoded values inside AccessToken.
    // const decodedTokenInfo = jwt.decode(AccessToken);
    const decodedTokenInfo = jose.decodeJwt(AccessToken);
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...existingUserValuesInStorage,
        ...passedUserDetails, // In most cases, this receive key values such as AccessToken, RefreshToken, isFederatedLogin.
        ...decodedTokenInfo, // Normally available data keys are userid, exp, authorized, access_uuid.)
      })
    );
  } catch {
    logoutUser();
  }
}
