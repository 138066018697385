import lodash from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routePaths } from "../routes/routeConstants";
import { logoutUser, saveLoggedUser } from "./userAuthentication";
import { ENTITLEMENT_STATUS, USER_TYPES } from "../configs/constants";
import navigatePage from "../utilities/navigatePage";

export function verifyAuthentication(response) {
  const { AccessToken, RefreshToken } = response.result;
  if (response?.organization && response?.organization[0]) {
    const { OrganizationID, EntitlementStatus, EntitlementCards, IsPrimaryOwner, IsOrganizationAdmin, IsStandardUser, IsGuest } = response?.organization[0];
    const memeberType = (primary, admin, standard, guest) => {
      if (primary) {
        return USER_TYPES.PRIMARY;
      }

      if (admin) {
        return USER_TYPES.ADMIN;
      }

      if (standard) {
        return USER_TYPES.STANDARD;
      }

      if (guest) {
        return USER_TYPES.GUEST;
      }
    };

    const applicationID = (cards) => {
      const card = cards.find((c) => {
        return c.VerticalApplicationID === process.env.REACT_APP_APPLICATION_ID;
      });

      if (!card) {
        throw new Error("Authentication failed: User is not authenticated.");
      }

      return card["VerticalApplicationID"];
    };

    if (EntitlementStatus == ENTITLEMENT_STATUS.ACTIVE || EntitlementStatus == ENTITLEMENT_STATUS.PAYMENT_FAILED) {
      saveLoggedUser({
        AccessToken,
        RefreshToken,
        OrganizationID,
        UserRole: memeberType(IsPrimaryOwner, IsOrganizationAdmin, IsStandardUser, IsGuest),
        VerticalApplicationID: applicationID(EntitlementCards),
      });

      if (AccessToken !== "") {
        toast.success("You have logged in successfully.");
        window.location.href = routePaths.dashboardRoot;
      }
    } else {
      window.location.href = routePaths.notEntitledRoot;
    }
  } else {
    throw new Error("Authentication failed: User is not authenticated.");
  }
}

export function handleTokenExpirationApiProblem(response) {
  const { Status = "", Result = "" } = lodash.isObject(response.data) ? response.data : {};

  if (Status === false && Result.includes("Token Expired")) {
    // Checking where currently user at. According to that redirect to appropriate login page.
    let redirectTo = routePaths.loginRoot;
    const currentLocationPath = navigatePage.location.pathname;

    // If already on one of Login Page ignore redirecting.
    if (currentLocationPath === routePaths.loginRoot) {
      return;
    }

    if (currentLocationPath.startsWith(routePaths.dashboardRoot)) {
      redirectTo = routePaths.loginRoot;
    }

    logoutUser({
      redirectTo,
    });
    toast.warning("Login Expired. Please Login Again.");
  }
}

export const fromStorage = (type, key) => {
  const userString = localStorage.getItem(type);
  const user = JSON.parse(userString);
  if (user !== null) {
    const item = JSON.parse(localStorage.getItem(type));
    return item[key];
  }
};

export const slugify = (text) => {
  return (
    text &&
    text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase()
      .trim()
      .replace(/\s+/g, "_")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "_")
  );
};
