import { SCOPE_TYPES, USER_TYPES } from "../../configs/constants"
import { getPermissions } from "./witmegOrgServerRequests";
import { fromStorage } from "../helpers";
import find from "lodash/find";

export const getScopePermissions = async ({ orgID, appID, userID }) => {
    const request = {
        OrganizationID: orgID,
        ApplicationID: appID,
        UserID: userID,
    };

    const scope_result = await getPermissions(request);

    // const scope_result = {
    //     "Status": true,
    //     "TierFeatures": [
    //         {
    //             "_id": "64f82b9ba75150966a90ad0a",
    //             "TierID": "63d76c13a664d6f2b7dbae0a",
    //             "VerticalApplicationID": "632983961250b0105020d561",
    //             "Sector": "Hospitality",
    //             "BusinessCategoryID": "6331948c3444daff7180b692",
    //             "OrganizationID": "621738ca7bfb741e995d15b0",
    //             "PurchasedDate": "2023-09-06T07:34:51.235Z",
    //             "CreatedDate": "2023-09-06T07:34:51.235Z",
    //             "ModifiedDate": "0001-01-01T00:00:00Z",
    //             "CreatedUser": "",
    //             "ModifiedUser": "",
    //             "EntitlementCatalogueData": {
    //                 "Features": [
    //                     {
    //                         "CatalogueID": "64f82b9ca75150966a90ad0c",
    //                         "DisplayName": "Integrated Payments",
    //                         "FeatureID": "64100b501f05f1a2b8d9b206",
    //                         "FeatureName": "Integrated Payments",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9ca75150966a90ad0c"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9ca75150966a90ad0d",
    //                         "DisplayName": "Table Management",
    //                         "FeatureID": "64100b501f05f1a2b8d9b207",
    //                         "FeatureName": "Table Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9ca75150966a90ad0d"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9ca75150966a90ad0e",
    //                         "DisplayName": "Basic Reporting",
    //                         "FeatureID": "64100b501f05f1a2b8d9b20b",
    //                         "FeatureName": "Basic Reporting",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "REPORTS",
    //                                 "UIMenuRefID": "6461b10bac2d9d4ca284e469"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9ca75150966a90ad0e"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9ca75150966a90ad0f",
    //                         "DisplayName": "24X7 Email and Phone Support",
    //                         "FeatureID": "64100b501f05f1a2b8d9b20c",
    //                         "FeatureName": "24X7 Email and Phone Support",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9ca75150966a90ad0f"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9ca75150966a90ad10",
    //                         "DisplayName": "Cash Management",
    //                         "FeatureID": "64100b501f05f1a2b8d9b20e",
    //                         "FeatureName": "Cash Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9ca75150966a90ad10"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9da75150966a90ad11",
    //                         "DisplayName": "Bulk Order Payments",
    //                         "FeatureID": "64100b501f05f1a2b8d9b210",
    //                         "FeatureName": "Bulk Order Payments",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9da75150966a90ad11"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9da75150966a90ad12",
    //                         "DisplayName": "Realtime Table Status View",
    //                         "FeatureID": "64100b501f05f1a2b8d9b212",
    //                         "FeatureName": "Realtime Table Status View",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9da75150966a90ad12"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9da75150966a90ad13",
    //                         "DisplayName": "Track Order Status",
    //                         "FeatureID": "64100b501f05f1a2b8d9b213",
    //                         "FeatureName": "Track Order Status",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9da75150966a90ad13"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9da75150966a90ad14",
    //                         "DisplayName": "Delivery Order Management",
    //                         "FeatureID": "64100b501f05f1a2b8d9b214",
    //                         "FeatureName": "Delivery Order Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9da75150966a90ad14"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9da75150966a90ad15",
    //                         "DisplayName": "Integrated Weighing Scales",
    //                         "FeatureID": "64100b501f05f1a2b8d9b217",
    //                         "FeatureName": "Integrated Weighing Scales",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9da75150966a90ad15"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9da75150966a90ad16",
    //                         "DisplayName": "Finger Print Integrations",
    //                         "FeatureID": "64100b501f05f1a2b8d9b218",
    //                         "FeatureName": "Finger Print Integrations",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9da75150966a90ad16"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9ea75150966a90ad17",
    //                         "DisplayName": "Online Table Reservation",
    //                         "FeatureID": "64100b501f05f1a2b8d9b219",
    //                         "FeatureName": "Online Table Reservation",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9ea75150966a90ad17"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9ea75150966a90ad18",
    //                         "DisplayName": "Witmeg SMS",
    //                         "FeatureID": "64100b501f05f1a2b8d9b21e",
    //                         "FeatureName": "Witmeg SMS",
    //                         "FeatureTypeID": "63d74cf9a664d6f2b7dbadb1",
    //                         "IsPurchased": false,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9ea75150966a90ad18"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9ea75150966a90ad19",
    //                         "DisplayName": "WITMEG KDS",
    //                         "FeatureID": "64100b501f05f1a2b8d9b224",
    //                         "FeatureName": "NeutriPOS Kitchen Display",
    //                         "FeatureTypeID": "63d74cf9a664d6f2b7dbadb1",
    //                         "IsPurchased": false,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9ea75150966a90ad19"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9ea75150966a90ad1a",
    //                         "DisplayName": "WITMEG Kiosk",
    //                         "FeatureID": "64100b501f05f1a2b8d9b225",
    //                         "FeatureName": "NeutriPOS Self Service",
    //                         "FeatureTypeID": "63d74cf9a664d6f2b7dbadb1",
    //                         "IsPurchased": false,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9ea75150966a90ad1a"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9fa75150966a90ad1c",
    //                         "DisplayName": "Shift Management",
    //                         "FeatureID": "64100b501f05f1a2b8d9b204",
    //                         "FeatureName": "Shift Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "Staff Profile",
    //                                 "UIMenuRefID": "64ca2aeac3dd5112749a304e"
    //                             },
    //                             {
    //                                 "MenuName": "Staff Role Mapping",
    //                                 "UIMenuRefID": "64f064edd16036a1881a626b"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9fa75150966a90ad1c"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9fa75150966a90ad1d",
    //                         "DisplayName": "Unlimited Products",
    //                         "FeatureID": "64100b501f05f1a2b8d9b205",
    //                         "FeatureName": "Unlimited Products",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "Product",
    //                                 "UIMenuRefID": "6461b0cc81c322e0409fd39e"
    //                             },
    //                             {
    //                                 "MenuName": "Category",
    //                                 "UIMenuRefID": "6461b0d68563451324cfea31"
    //                             },
    //                             {
    //                                 "MenuName": "Offers",
    //                                 "UIMenuRefID": "6461b0de4015494739708051"
    //                             },
    //                             {
    //                                 "MenuName": "Modifier Groups",
    //                                 "UIMenuRefID": "6461b0d68563451324cfea38"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9fa75150966a90ad1d"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9fa75150966a90ad1e",
    //                         "DisplayName": "Takeaway and Collections Orders",
    //                         "FeatureID": "64100b501f05f1a2b8d9b208",
    //                         "FeatureName": "Takeaway and Collections Orders",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9fa75150966a90ad1e"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9fa75150966a90ad1f",
    //                         "DisplayName": "Debtor Management",
    //                         "FeatureID": "64100b501f05f1a2b8d9b209",
    //                         "FeatureName": "Debtor Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9fa75150966a90ad1f"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9fa75150966a90ad20",
    //                         "DisplayName": "Discount Management",
    //                         "FeatureID": "64100b501f05f1a2b8d9b20a",
    //                         "FeatureName": "Discount Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9fa75150966a90ad20"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82b9fa75150966a90ad21",
    //                         "DisplayName": "Unlimited Printers",
    //                         "FeatureID": "64100b501f05f1a2b8d9b20d",
    //                         "FeatureName": "Unlimited Printers",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82b9fa75150966a90ad21"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba0a75150966a90ad22",
    //                         "DisplayName": "Customer Management",
    //                         "FeatureID": "64100b501f05f1a2b8d9b20f",
    //                         "FeatureName": "Customer Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba0a75150966a90ad22"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba0a75150966a90ad24",
    //                         "DisplayName": "Additional Payment Types",
    //                         "FeatureID": "64100b501f05f1a2b8d9b211",
    //                         "FeatureName": "Additional Payment Types",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba0a75150966a90ad24"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba0a75150966a90ad26",
    //                         "DisplayName": "Integrated Phone Line",
    //                         "FeatureID": "64100b501f05f1a2b8d9b216",
    //                         "FeatureName": "Integrated Phone Line",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba0a75150966a90ad26"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba1a75150966a90ad27",
    //                         "DisplayName": "",
    //                         "FeatureID": "64100b501f05f1a2b8d9b21b",
    //                         "FeatureName": "",
    //                         "FeatureTypeID": "63d75391a664d6f2b7dbadb7",
    //                         "IsPurchased": false,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba1a75150966a90ad27"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba1a75150966a90ad28",
    //                         "DisplayName": "",
    //                         "FeatureID": "64100b501f05f1a2b8d9b21c",
    //                         "FeatureName": "",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "Forecasted Data",
    //                                 "UIMenuRefID": "6461b112345666f7ac8eee23"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba1a75150966a90ad28"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba1a75150966a90ad29",
    //                         "DisplayName": "WhatsApp",
    //                         "FeatureID": "64100b501f05f1a2b8d9b21d",
    //                         "FeatureName": "WhatsApp",
    //                         "FeatureTypeID": "63d74cf9a664d6f2b7dbadb1",
    //                         "IsPurchased": false,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba1a75150966a90ad29"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba1a75150966a90ad2a",
    //                         "DisplayName": "Email Marketting",
    //                         "FeatureID": "64100b501f05f1a2b8d9b221",
    //                         "FeatureName": "Email Marketting",
    //                         "FeatureTypeID": "63d74cf9a664d6f2b7dbadb1",
    //                         "IsPurchased": false,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba1a75150966a90ad2a"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba1a75150966a90ad2b",
    //                         "DisplayName": "Bulk Upload",
    //                         "FeatureID": "64100b501f05f1a2b8d9b222",
    //                         "FeatureName": "Bulk Upload",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba1a75150966a90ad2b"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba2a75150966a90ad2c",
    //                         "DisplayName": "TestFeature",
    //                         "FeatureID": "64100b501f05f1a2b8d9b21f",
    //                         "FeatureName": "Postcode Lookup - Loqate",
    //                         "FeatureTypeID": "63d74cf9a664d6f2b7dbadb1",
    //                         "IsPurchased": false,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba2a75150966a90ad2c"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba2a75150966a90ad2d",
    //                         "DisplayName": "WITMEG WaiterPAD",
    //                         "FeatureID": "64100b501f05f1a2b8d9b223",
    //                         "FeatureName": "NPOS Waiterpad Service",
    //                         "FeatureTypeID": "63d74cf9a664d6f2b7dbadb1",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba2a75150966a90ad2d"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba2a75150966a90ad2e",
    //                         "DisplayName": "NeutriPOS Customer Facing",
    //                         "FeatureID": "64100b501f05f1a2b8d9b226",
    //                         "FeatureName": "NeutriPOS Customer Facing",
    //                         "FeatureTypeID": "63d74cf9a664d6f2b7dbadb1",
    //                         "IsPurchased": false,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba2a75150966a90ad2e"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba2a75150966a90ad30",
    //                         "DisplayName": "Uber Eats",
    //                         "FeatureID": "64af6f41b7943afc0a56a71d",
    //                         "FeatureName": "Uber Eats",
    //                         "FeatureTypeID": "63d75391a664d6f2b7dbadb7",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "Uber Eats",
    //                                 "UIMenuRefID": "6461b1431131727fae1adca1"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba2a75150966a90ad30"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba2a75150966a90ad30",
    //                         "DisplayName": "Deliveroo",
    //                         "FeatureID": "64af6f41b7943afc0a56a71d",
    //                         "FeatureName": "Deliveroo",
    //                         "FeatureTypeID": "63d75391a664d6f2b7dbadb7",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Optional",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "Deliveroo",
    //                                 "UIMenuRefID": "6461b1431131727fae1adca2"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba2a75150966a90ad30"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba2a75150966a90ad31",
    //                         "DisplayName": "Sales Management",
    //                         "FeatureID": "64f070e2ddeedaf3fb6a7b22",
    //                         "FeatureName": "Sales Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "Sales Invoice (Perfoma)",
    //                                 "UIMenuRefID": "6461b10025e519d86c9588d7"
    //                             },
    //                             {
    //                                 "MenuName": "Customer",
    //                                 "UIMenuRefID": "6461b117274e724458adb986"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba2a75150966a90ad31"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba3a75150966a90ad32",
    //                         "DisplayName": "Menu Management",
    //                         "FeatureID": "64f0712addeedaf3fb6a7b23",
    //                         "FeatureName": "Menu Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "Menu Layouts",
    //                                 "UIMenuRefID": "6461b0f18cabf4ad256dc955"
    //                             },
    //                             {
    //                                 "MenuName": "Menu History",
    //                                 "UIMenuRefID": "6461b0f18cabf4ad256dc956"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba3a75150966a90ad32"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba3a75150966a90ad33",
    //                         "DisplayName": "Stock Management",
    //                         "FeatureID": "64f079d2ddeedaf3fb6a7b27",
    //                         "FeatureName": "Stock Management",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "Re-order Management",
    //                                 "UIMenuRefID": "6461b0e5fe445912293c602d"
    //                             },
    //                             {
    //                                 "MenuName": "Unit type",
    //                                 "UIMenuRefID": "64f064daaadcc76cd301c930"
    //                             },
    //                             {
    //                                 "MenuName": "Manual Stock Adjustment",
    //                                 "UIMenuRefID": "6461b0ebaa2cb2027a9b90ad"
    //                             },
    //                             {
    //                                 "MenuName": "Purchase Order",
    //                                 "UIMenuRefID": "6461b0f615184e452c16e7ce"
    //                             },
    //                             {
    //                                 "MenuName": "Good Receive Note",
    //                                 "UIMenuRefID": "6461b0fccf3fa3544fb3fdf1"
    //                             },
    //                             {
    //                                 "MenuName": "Purchase Order",
    //                                 "UIMenuRefID": "64f064f1d846bee2d7422a8d"
    //                             },
    //                             {
    //                                 "MenuName": "Good Receive Note",
    //                                 "UIMenuRefID": "64f064f6e75a920828e5b8c2"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba3a75150966a90ad33"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba3a75150966a90ad34",
    //                         "DisplayName": "Settings",
    //                         "FeatureID": "64f07c2bddeedaf3fb6a7b2b",
    //                         "FeatureName": "Settings",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "POS settings",
    //                                 "UIMenuRefID": "6461b10544d8bc454d6a3018"
    //                             },
    //                             {
    //                                 "MenuName": "Customer settings",
    //                                 "UIMenuRefID": "6461b11e40817fb8c8380741"
    //                             },
    //                             {
    //                                 "MenuName": "Printer settings",
    //                                 "UIMenuRefID": "6461b12647b31a0ca6b297a9"
    //                             },
    //                             {
    //                                 "MenuName": "Payments settings",
    //                                 "UIMenuRefID": "6461b12ca73ff7c4dedfa6e1"
    //                             },
    //                             {
    //                                 "MenuName": "Loyalty",
    //                                 "UIMenuRefID": "6461b13370c53c5bdcbe19a5"
    //                             },
    //                             {
    //                                 "MenuName": "Weighing Scales Integration",
    //                                 "UIMenuRefID": "6461b1388fbb825a1a16ba63"
    //                             },
    //                             {
    //                                 "MenuName": "Integrated Phone Line",
    //                                 "UIMenuRefID": "6461b13ea34a5f4fc533b41b"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba3a75150966a90ad34"
    //                     },
    //                     {
    //                         "CatalogueID": "64f82ba3a75150966a90ad35",
    //                         "DisplayName": "Dashboard",
    //                         "FeatureID": "64f569ff3c032873b8d32446",
    //                         "FeatureName": "Dashboard",
    //                         "FeatureTypeID": "63d74d02a664d6f2b7dbadb2",
    //                         "IsPurchased": true,
    //                         "IsRecommended": true,
    //                         "OrganizationID": "621738ca7bfb741e995d15b0",
    //                         "SubAppRelation": [],
    //                         "TierID": "63d76c13a664d6f2b7dbae0a",
    //                         "Type": "Features",
    //                         "UIMenuReferences": [
    //                             {
    //                                 "MenuName": "Dashboard",
    //                                 "UIMenuRefID": "64f557efe4f8b66a82093311"
    //                             }
    //                         ],
    //                         "VerticalID": "639060ad8b06bd4a370e0d06",
    //                         "VerticalPrimaryappID": "632983961250b0105020d561",
    //                         "_id": "64f82ba3a75150966a90ad35"
    //                     }
    //                 ]
    //             }
    //         }
    //     ],
    //     "AppGroupPermissions": [
    //         {
    //             "ID": "63970450b2d1573c890e5eb1",
    //             "ModuleID": "63970450b2d1573c890e5eb1",
    //             "ModuleName": "Inventory",
    //             "ModuleDisplayName": "Inventory",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": true,
    //             "PermisssionSet": null,
    //             "AppSubModulePermissionSets": [
    //                 {
    //                     "ID": "63970bfd83d924c029d3ede4",
    //                     "ModuleID": "63970450b2d1573c890e5eb1",
    //                     "SubModuleName": "Product",
    //                     "SubModuleDisplayName": "Product",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Download": {
    //                             "DisplayName": "Download",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Upload": {
    //                             "DisplayName": "Upload",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0cc81c322e0409fd39e"
    //                 },
    //                 {
    //                     "ID": "63970d7083d924c029d3ede6",
    //                     "ModuleID": "63970450b2d1573c890e5eb1",
    //                     "SubModuleName": "Offer",
    //                     "SubModuleDisplayName": "Offer",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0de4015494739708051"
    //                 },
    //                 {
    //                     "ID": "63970d4983d924c029d3ede5",
    //                     "ModuleID": "63970450b2d1573c890e5eb1",
    //                     "SubModuleName": "Category",
    //                     "SubModuleDisplayName": "Category",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0d68563451324cfea31"
    //                 },
    //                 {
    //                     "ID": "646d955d07f3b81567d2d72c",
    //                     "ModuleID": "63970450b2d1573c890e5eb1",
    //                     "SubModuleName": "Re-order Managment ",
    //                     "SubModuleDisplayName": "Re-order Managment ",
    //                     "SubPermissionSetValue": {
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": false,
    //                             "IsActive": true
    //                         },
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": false,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0e5fe445912293c602d"
    //                 },
    //                 {
    //                     "ID": "646d958d07f3b81567d2d72d",
    //                     "ModuleID": "63970450b2d1573c890e5eb1",
    //                     "SubModuleName": "Manual Stock Adjustment",
    //                     "SubModuleDisplayName": "Manual Stock Adjustment",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0ebaa2cb2027a9b90ad"
    //                 },
    //                 {
    //                     "ID": "64f599423c032873b8d3245a",
    //                     "ModuleID": "63970450b2d1573c890e5eb1",
    //                     "SubModuleName": "Unit type",
    //                     "SubModuleDisplayName": "Unit type",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": false,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": false,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "64f064daaadcc76cd301c930"
    //                 },
    //                 {
    //                     "ID": "63970d4983d924c029d3ede6",
    //                     "ModuleID": "63970450b2d1573c890e5eb1",
    //                     "SubModuleName": "Modifier Group",
    //                     "SubModuleDisplayName": "Modifier Group",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0d68563451324cfea38"
    //                 }
    //             ],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": ""
    //         },
    //         {
    //             "ID": "63970fb4b2d1573c890e5eb8",
    //             "ModuleID": "63970fb4b2d1573c890e5eb8",
    //             "ModuleName": "FileActivity",
    //             "ModuleDisplayName": "FileActivity",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": false,
    //             "PermisssionSet": {
    //                 "Download": {
    //                     "DisplayName": "Download",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 },
    //                 "ProductSummary": {
    //                     "DisplayName": "ProductSummary",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 },
    //                 "Upload": {
    //                     "DisplayName": "Upload",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 },
    //                 "View": {
    //                     "DisplayName": "View",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 }
    //             },
    //             "AppSubModulePermissionSets": [],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": ""
    //         },
    //         {
    //             "ID": "639705f3b2d1573c890e5eb2",
    //             "ModuleID": "639705f3b2d1573c890e5eb2",
    //             "ModuleName": "Menu Management",
    //             "ModuleDisplayName": "Menu Management",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": true,
    //             "PermisssionSet": {
    //                 "Create": {
    //                     "DisplayName": "Create",
    //                     "IsSelected": true,
    //                     "IsActive": true
    //                 },
    //                 "Delete": {
    //                     "DisplayName": "Delete",
    //                     "IsSelected": true,
    //                     "IsActive": true
    //                 },
    //                 "Edit": {
    //                     "DisplayName": "Edit",
    //                     "IsSelected": true,
    //                     "IsActive": true
    //                 },
    //                 "View": {
    //                     "DisplayName": "View",
    //                     "IsSelected": true,
    //                     "IsActive": true
    //                 },

    //             },
    //             "AppSubModulePermissionSets": [
    //                 {
    //                     "ID": "63970bfd83d924c029d3xede4",
    //                     "ModuleID": "63970450b2d1573c890e5eb1",
    //                     "SubModuleName": "Menu Layouts",
    //                     "SubModuleDisplayName": "Menu Layouts",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Download": {
    //                             "DisplayName": "Download",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Upload": {
    //                             "DisplayName": "Upload",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0f18cabf4ad256dc955"
    //                 },
    //                 {
    //                     "ID": "63970bfd83d924c029d3ecde4",
    //                     "ModuleID": "63970450b2d1573c890e5eb1",
    //                     "SubModuleName": "Menu History",
    //                     "SubModuleDisplayName": "Menu History",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0f18cabf4ad256dc956"
    //                 },
    //             ],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": ""
    //         },
    //         {
    //             "ID": "63970629b2d1573c890e5eb3",
    //             "ModuleID": "63970629b2d1573c890e5eb3",
    //             "ModuleName": "Settings",
    //             "ModuleDisplayName": "Settings",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": true,
    //             "PermisssionSet": null,
    //             "AppSubModulePermissionSets": [
    //                 {
    //                     "ID": "63970e0e83d924c029d3ede7",
    //                     "ModuleID": "63970629b2d1573c890e5eb3",
    //                     "SubModuleName": "POS Settings",
    //                     "SubModuleDisplayName": "POS Settings",
    //                     "SubPermissionSetValue": {
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b10544d8bc454d6a3018"
    //                 },
    //                 {
    //                     "ID": "646d981107f3b81567d2d72f",
    //                     "ModuleID": "63970629b2d1573c890e5eb3",
    //                     "SubModuleName": "Customer settings",
    //                     "SubModuleDisplayName": "Customer settings",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b11e40817fb8c8380741"
    //                 },
    //                 {
    //                     "ID": "646d983907f3b81567d2d730",
    //                     "ModuleID": "63970629b2d1573c890e5eb3",
    //                     "SubModuleName": "Printer settings",
    //                     "SubModuleDisplayName": "Printer settings",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b12647b31a0ca6b297a9"
    //                 },
    //                 {
    //                     "ID": "646d986c07f3b81567d2d731",
    //                     "ModuleID": "63970629b2d1573c890e5eb3",
    //                     "SubModuleName": "Payments",
    //                     "SubModuleDisplayName": "Payments",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b12ca73ff7c4dedfa6e1"
    //                 },
    //                 {
    //                     "ID": "646d989607f3b81567d2d732",
    //                     "ModuleID": "63970629b2d1573c890e5eb3",
    //                     "SubModuleName": "Loyalty",
    //                     "SubModuleDisplayName": "Loyalty",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b13370c53c5bdcbe19a5"
    //                 },
    //                 {
    //                     "ID": "646d98f007f3b81567d2d733",
    //                     "ModuleID": "63970629b2d1573c890e5eb3",
    //                     "SubModuleName": "Weighing Scales Integration",
    //                     "SubModuleDisplayName": "Weighing Scales Integration",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b1388fbb825a1a16ba63"
    //                 },
    //                 {
    //                     "ID": "646d996007f3b81567d2d734",
    //                     "ModuleID": "63970629b2d1573c890e5eb3",
    //                     "SubModuleName": "Phone Line Integration",
    //                     "SubModuleDisplayName": "Phone Line Integration",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b13ea34a5f4fc533b41b"
    //                 }
    //             ],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": ""
    //         },
    //         {
    //             "ID": "63970b91b2d1573c890e5eb6",
    //             "ModuleID": "63970b91b2d1573c890e5eb6",
    //             "ModuleName": "Reports",
    //             "ModuleDisplayName": "Reports",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": false,
    //             "PermisssionSet": {
    //                 "Create": {
    //                     "DisplayName": "Create",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 },
    //                 "Delete": {
    //                     "DisplayName": "Delete",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 },
    //                 "Edit": {
    //                     "DisplayName": "Edit",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 },
    //                 "View": {
    //                     "DisplayName": "View",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 }
    //             },
    //             "AppSubModulePermissionSets": [],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": "6461b10bac2d9d4ca284e469"
    //         },
    //         {
    //             "ID": "646d964e97134213d2a5a14c",
    //             "ModuleID": "646d964e97134213d2a5a14c",
    //             "ModuleName": "DELIVERY APPS",
    //             "ModuleDisplayName": "DELIVERY APPS",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": false,
    //             "PermisssionSet": {
    //                 "Create": {
    //                     "DisplayName": "Create",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 },
    //                 "Delete": {
    //                     "DisplayName": "Delete",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 },
    //                 "Edit": {
    //                     "DisplayName": "Edit",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 },
    //                 "View": {
    //                     "DisplayName": "View",
    //                     "IsSelected": false,
    //                     "IsActive": true
    //                 }
    //             },
    //             "AppSubModulePermissionSets": [],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": "6461b1431131727fae1adca1"
    //         },
    //         {
    //             "ID": "646d969897134213d2a5a14d",
    //             "ModuleID": "646d969897134213d2a5a14d",
    //             "ModuleName": "FORECASTING ",
    //             "ModuleDisplayName": "FORECASTING ",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": true,
    //             "PermisssionSet": null,
    //             "AppSubModulePermissionSets": [
    //                 {
    //                     "ID": "646d975607f3b81567d2d72e",
    //                     "ModuleID": "646d969897134213d2a5a14d",
    //                     "SubModuleName": "Forecasted Data ",
    //                     "SubModuleDisplayName": "Forecasted Data ",
    //                     "SubPermissionSetValue": {
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": false,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b112345666f7ac8eee23"
    //                 }
    //             ],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": ""
    //         },
    //         {
    //             "ID": "646d96ef97134213d2a5a14e",
    //             "ModuleID": "646d96ef97134213d2a5a14e",
    //             "ModuleName": "PURCHASE",
    //             "ModuleDisplayName": "PURCHASE",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": true,
    //             "PermisssionSet": null,
    //             "AppSubModulePermissionSets": [
    //                 {
    //                     "ID": "646d9b4007f3b81567d2d735",
    //                     "ModuleID": "646d96ef97134213d2a5a14e",
    //                     "SubModuleName": "Purchase Order ",
    //                     "SubModuleDisplayName": "Purchase Order ",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0f615184e452c16e7ce"
    //                 },
    //                 {
    //                     "ID": "646d9b9607f3b81567d2d736",
    //                     "ModuleID": "646d96ef97134213d2a5a14e",
    //                     "SubModuleName": "Good Receive Note ",
    //                     "SubModuleDisplayName": "Good Receive Note ",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b0fccf3fa3544fb3fdf1"
    //                 }
    //             ],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": ""
    //         },
    //         {
    //             "ID": "646d9bd097134213d2a5a14f",
    //             "ModuleID": "646d9bd097134213d2a5a14f",
    //             "ModuleName": "SALES",
    //             "ModuleDisplayName": "SALES",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": true,
    //             "PermisssionSet": null,
    //             "AppSubModulePermissionSets": [
    //                 {
    //                     "ID": "646d9bfa07f3b81567d2d737",
    //                     "ModuleID": "646d9bd097134213d2a5a14f",
    //                     "SubModuleName": "Proforma",
    //                     "SubModuleDisplayName": "Proforma",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b10025e519d86c9588d7"
    //                 },
    //                 {
    //                     "ID": "646d9c4107f3b81567d2d738",
    //                     "ModuleID": "646d9bd097134213d2a5a14f",
    //                     "SubModuleName": "Customer",
    //                     "SubModuleDisplayName": "Customer",
    //                     "SubPermissionSetValue": {
    //                         "Create": {
    //                             "DisplayName": "Create",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Delete": {
    //                             "DisplayName": "Delete",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b117274e724458adb986"
    //                 }
    //             ],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": ""
    //         },
    //         {
    //             "ID": "64f570683c032873b8d32454",
    //             "ModuleID": "64f570683c032873b8d32454",
    //             "ModuleName": "Service Providers",
    //             "ModuleDisplayName": "Service Providers",
    //             "ApplicationID": "632983961250b0105020d561",
    //             "HasSubModules": true,
    //             "PermisssionSet": null,
    //             "AppSubModulePermissionSets": [
    //                 {
    //                     "ID": "64f571a13c032873b8d32455",
    //                     "ModuleID": "64f570683c032873b8d32454",
    //                     "SubModuleName": "Uber Eats",
    //                     "SubModuleDisplayName": "Uber Eats",
    //                     "SubPermissionSetValue": {
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b1431131727fae1adca1"
    //                 },
    //                 {
    //                     "ID": "64f571a13c032873b8d32455",
    //                     "ModuleID": "64f570683c032873b8d32454",
    //                     "SubModuleName": "Deliveroo",
    //                     "SubModuleDisplayName": "Deliveroo",
    //                     "SubPermissionSetValue": {
    //                         "Edit": {
    //                             "DisplayName": "Edit",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         },
    //                         "View": {
    //                             "DisplayName": "View",
    //                             "IsSelected": true,
    //                             "IsActive": true
    //                         }
    //                     },
    //                     "UIMenuRefID": "6461b1431131727fae1adca2"
    //                 }
    //             ],
    //             "CreatedDate": "2022-10-29T19:02:15.993Z",
    //             "ModifiedDate": "2022-10-29T19:02:15.993Z",
    //             "IsActive": true,
    //             "UIMenuRefID": ""
    //         }
    //     ],
    //     "IsLicenseAvAilable": true
    // }

    const userRole = fromStorage("user", "UserRole");
    // const permissionGroup =
    //     userRole === USER_TYPES.ADMIN
    //         ? adminPermissions
    //         : scope_result.AppGroupPermissions;

    const permissionGroup = await scope_result.AppGroupPermissions;
    const tierFeatures = scope_result.TierFeatures[0][
        "EntitlementCatalogueData"
    ]["Features"].filter((feature) => {
        return feature.UIMenuReferences;
    });

    const menus = [];
    const mergedMenus = [];

    if (permissionGroup) {
        tierFeatures.forEach((feature) => {
            feature.UIMenuReferences.forEach((menu) => {
                const withoutSubMenu = permissionGroup && permissionGroup.filter(
                    (permission) => permission.UIMenuRefID == menu.UIMenuRefID
                );
                const withSubMenu = permissionGroup && permissionGroup.filter(
                    (permission) => permission.UIMenuRefID == ""
                );

                if (withoutSubMenu.length > 0) {
                    const permissionSet = Object.entries(
                        withoutSubMenu[0].PermisssionSet
                    );
                    const menuPermissions = permissionSet
                        .map(
                            (set) =>
                                set[1].IsSelected && set[0].toUpperCase()
                        )
                        .filter((p) => p);
                    const resultWithoutSubMenu = {
                        name: withoutSubMenu[0].ModuleName,
                        id: withoutSubMenu[0].ID,
                        ref: menu.UIMenuRefID,
                        type: SCOPE_TYPES.MAIN_MENU,
                        permissions: menuPermissions,
                        isSubscribed: feature.IsPurchased,
                        isPromotable: feature.IsRecommended
                            ? feature.IsRecommended
                            : false,
                    };

                    const exists = find(menus, { id: withoutSubMenu.ID });
                    !exists && menus.push(resultWithoutSubMenu);
                }


                withSubMenu &&
                    withSubMenu.forEach((mainMenu) => {
                        const subMenu =
                            mainMenu.HasSubModules &&
                            find(mainMenu.AppSubModulePermissionSets, {
                                UIMenuRefID: menu.UIMenuRefID,
                            });

                        if (subMenu) {
                            const permissionSet = Object.entries(
                                subMenu.SubPermissionSetValue
                            );
                            const subPermissions = permissionSet
                                .map(
                                    (set) =>
                                        set[1].IsSelected && set[0].toUpperCase()
                                )
                                .filter((p) => p);

                            console.log("subPermissions", subPermissions);
                            const subMenuObj = {
                                name: subMenu.SubModuleName,
                                id: subMenu.ID,
                                ref: menu.UIMenuRefID,
                                type: SCOPE_TYPES.SUB_MENU,
                                isSubscribed: feature.IsPurchased,
                                permissions: subPermissions,
                                subModuleID: subMenu.ID

                            };

                            const mainMenuObj = {
                                name: mainMenu.ModuleName,
                                id: mainMenu.ID,
                                ref: mainMenu.UIMenuRefID,
                                type: SCOPE_TYPES.MAIN_MENU,
                                isSubscribed: feature.IsPurchased,
                                isPromotable: feature.IsRecommended
                                    ? feature.IsRecommended
                                    : false,
                                subMenus: [subMenuObj],
                                mainModuleID: mainMenu.ModuleID
                            };
                            menus.push(mainMenuObj);
                        }
                    });
            });
        });
    }


    menus.length > 0 &&
        menus.forEach((menu) => {
            const mergedMenu = find(mergedMenus, { id: menu.id });

            !mergedMenu
                ? mergedMenus.push(menu)
                : mergedMenu.subMenus.push(...menu.subMenus);
        });

    console.log("tierFeatures", tierFeatures);

    const scope = {
        isLicenseActive: scope_result.IsLicenseAvAilable,
        tierFeatures: mergedMenus,
    };

    console.log("scope", scope);

    return scope;
};

export const checkPermission = (
    scope,
    type = "",
    moduleID,
    permission = "",
    ref
) => {
    const menus = scope.tierFeatures;
    const subMenus =
        menus &&
        menus
            .map((menu) => menu.subMenus)
            .filter((p) => p)
            .flat();

    // if (type === SCOPE_TYPES.FEATURE) {
    //     const feature = scope.tierFeatures.find(
    //         (tier) => tier.type === SCOPE_TYPES.FEATURE && tier.id === moduleID
    //     );

    //     return feature ? feature : false;
    // }

    if (type === SCOPE_TYPES.MAIN_MENU) {
        return menus;
    }

    if (type === SCOPE_TYPES.SUB_MENU && permission) {
        const subMenuWithPermissions =
            subMenus && subMenus.find((sub) => sub.id === moduleID);
        const isPermissionExists =
            subMenuWithPermissions &&
            subMenuWithPermissions.permissions.includes(permission);
        return isPermissionExists;
    }

    if (ref) {
        return type === SCOPE_TYPES.MAIN_MENU
            ? menus.find((m) => m.ref === ref)
            : subMenus.find((sub) => sub.ref === ref);
    }
};
