const loginRoot = "/login";
const dashboardRoot = "/";
const paymentOnboardingRoot = "/paymentOnboarding/view-Application";
const reportView = "/reportView";

export const routePaths = {
  loginRoot,
  dashboardRoot,
  paymentOnboardingRoot,

  // ****** Dashboard -> Report  View ******
  dashboard__reportView: `${reportView}`,

  // ****** Dashboard -> paymentOnboarding -> XXX ******
  dashboard__paymentOnboarding__view_Application: `${paymentOnboardingRoot}`,
  dashboard__paymentOnboarding__onboarding: `${paymentOnboardingRoot}/Onboarding`,
  dashboard__paymentOnboarding__onboardingStatus: `${paymentOnboardingRoot}/OnboardingStatus`,
  dashboard__paymentOnboarding__termsAndConditions: `${paymentOnboardingRoot}/TermsAndConditions`,
  dashboard__paymentOnboarding__requiredAction: `${paymentOnboardingRoot}/View`,
};
