import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routePaths } from "./routeConstants";
import Dashboard from "../pages/Dashboard";
import CustomerOnboarding from "../pages/CustomerOnboarding";
import ViewApplication from "../pages/ViewApplication";
import LoginPage from "../pages/LoginPage";
//import OnboardingStatus from "../pages/OnboardingStatus.js";
import TermsAndConditions from "../pages/TermsAndConditions";
import View from "../pages/View";
import MainReportsPage from "../pages/MainReportsPage";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes history={history}>
        <Route path={routePaths.loginRoot} element={<LoginPage />} />
        <Route path={"/"} element={<Dashboard />} />
        <Route path="/" element={<Dashboard />}>
          <Route path={`${routePaths.dashboard__paymentOnboarding__onboarding}`} element={<CustomerOnboarding />} />
          <Route path={routePaths.dashboard__paymentOnboarding__view_Application} element={<ViewApplication />} />
          <Route path={routePaths.dashboard__paymentOnboarding__termsAndConditions} element={<TermsAndConditions />} />
          <Route path={routePaths.dashboard__paymentOnboarding__requiredAction} element={<View />} />
          <Route path={`${routePaths.dashboard__reportView}/:id`} element={<MainReportsPage />} />

          {/* <Route path={routePaths.dashboard__paymentOnboarding__onboardingStatus} element={<OnboardingStatus />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default MainRoutes;
