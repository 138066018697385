import React, { useEffect, useState } from "react";
import { GlobalLayout, GlobalSidePanel, GlobalHeader, GlobalFooter, Icon, SideMenuWithToggle, Typography, Button } from "witmeg-ui-system";
import { getLoggedUserId, logoutUser } from "../../utilities/userAuthentication";
import { Tooltip } from "antd";
import { routePaths } from "../../routes/routeConstants";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { generateToken } from "../../utilities/apiRequests/tokenGenerators";
import { useDispatch, useSelector } from "react-redux";
import { fromStorage, slugify } from "../../utilities/helpers";
import { fetchUserDetailsById, fetchUserDataById } from "../../redux/dashboardSlice";
import { fetchTokenDetails } from "../../redux/tokenSlice";
import { reqStatusTypes } from "../../configs/constants";
import { setLocationSelectorData } from "../../redux/dashboardSlice";
import { getScopePermissions, checkPermission } from "../../utilities/apiRequests/scopePermissions";
import { MENU_NAMES, PERMISSION_TYPES, SCOPE_REFERENCES, SCOPE_TYPES } from "../../configs/constants";
import recommendationIcon from "../../assets/recommendation.png";
import { getReportListByAppIDAndOrgID } from "../../utilities/apiRequests/witmegReportingServerRequests";

function Dashboard() {
  const reportID = process.env.REACT_APP_REPORT_ID;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dashboardSliceState = useSelector((state) => state.dashboard);
  const [menuState, setMenuState] = useState(false);
  const [locationSelectorDetails, setLocationSelectorDetails] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [scopeDetails, setScopeDetails] = useState({});
  const [reportList, setReportList] = useState(null);
  const [menus, setMenus] = useState([]);

  const menuItems = (title, isSubscribed, isPromotable, permissions = [], reportList = { id: "", name: "" }) => {
    switch (title) {
      case MENU_NAMES.HOME:
        return {
          icon: <Icon name="home" />,
          menuTitle: <Link to={routePaths.dashboard}>HOME</Link>,
        };
        break;

      case MENU_NAMES.ONBOARDING:
        return validateSubscription(
          {
            icon: <Icon name="home" />,
            title: "Onboarding",
            menuTitle: "Onboarding",
          },
          isSubscribed,
          isPromotable
        );
        break;

      case MENU_NAMES.TERMS_AND_CONDITIONS:
        return validateSubscription(
          {
            icon: <Icon name="home" />,
            title: "Terms and Conditions",
            menuTitle: "Terms and Condition",
          },
          isSubscribed,
          isPromotable
        );
        break;

      case MENU_NAMES.VIEW_APPLICATION:
        return validatedMenu(
          {
            icon: <Icon name="layers" />,
            title: "View Applications",
            menuTitle: <Link to={routePaths.dashboard__paymentOnboarding__view_Application}>View Applications</Link>,
          },
          isSubscribed,
          permissions
        );
        break;

      case MENU_NAMES.REPORTS:
        return validateSubscription(
          {
            icon: <Icon name="four-squares" />,
            title: "Reports",
            menuTitle: "Reports",
          },
          isSubscribed,
          isPromotable
        );
        break;

      case MENU_NAMES.REPORT_LIST:
        return validatedMenu(
          {
            icon: <Icon name="dashboard" />,
            title: reportList.name,
            menuTitle: (
              <Tooltip title={reportList.name}>
                <Link
                  to={`${routePaths.dashboard__reportView}/${reportList.id}`}
                  style={{
                    maxWidth: "100px",
                    whiteSpace: "inherit",
                  }}
                >
                  {reportList.name}
                </Link>
              </Tooltip>
            ),
          },
          isSubscribed,
          permissions
        );
        break;

      case MENU_NAMES.LOGOUT:
        return {
          icon: <Icon name="logout" />,
          menuTitle: "LOGOUT",
          onClick: () => {
            logoutUser({
              redirectTo: routePaths.loginRoot,
            });
          },
        };
        break;

      // default: return {
      //     onClick: () => {
      //         logoutUser({
      //             redirectTo: `${routePaths}/dashborad_unknown`,
      //         });
      //     },
      // };
      //     break;
    }
  };

  console.log("menuItems", menuItems);

  const validateSubscription = (menu, isSubscribed, isPromotable) => {
    return isSubscribed
      ? menu
      : isPromotable
      ? {
          icon: <div>{menu.icon}</div>,
          menuTitle: tooltipComponent(menu, isPromotable),
        }
      : {
          icon: <div>{menu.icon}</div>,
          menuTitle: tooltipComponent(menu, isPromotable),
        };
  };

  const getSearchResults = async () => {
    if (dashboardSliceState && dashboardSliceState.currentlySelectedOrgId) {
      const reportListData = await getReportListByAppIDAndOrgID({
        OrgID: dashboardSliceState.currentlySelectedOrgId,
        appId: reportID,
      });

      //  const reportListData = [{ id: "13132132", name: "dgdgdf" }];

      const reportMenuItems = reportListData
        ? reportListData?.map((item, idx) => {
            return {
              id: item.id,
              name: item.name,
              permissions: [PERMISSION_TYPES.VIEW],
              ref: "",
              type: SCOPE_TYPES.SUB_MENU,
              category: MENU_NAMES.REPORT_LIST,
              isSubscribed: true,
            };
          })
        : [];
      setReportList(reportMenuItems);
    }
  };

  useEffect(() => {
    if (dashboardSliceState && dashboardSliceState.currentlySelectedOrgId) {
      getSearchResults();
    }
  }, [dashboardSliceState]);

  useEffect(() => {
    if (Object.keys(scopeDetails).length > 0) {
      let menus = checkPermission(scopeDetails, SCOPE_TYPES.MAIN_MENU);

      let reportMenu = menus.find((menu) => menu.ref === SCOPE_REFERENCES.REPORTS && menu.isSubscribed);

      if (reportList && reportList.length > 0 && reportMenu) {
        reportMenu = { ...reportMenu, subMenus: reportList };
        menus = menus.filter((menu) => menu.ref !== SCOPE_REFERENCES.REPORTS);
        menus.push(reportMenu);
      }

      const menuList = [
        {
          name: "Home",
          isSubscribed: true,
        },
        ...menus,
        {
          name: "Logout",
          isSubscribed: true,
        },
      ];
      generateMenus(menuList);
    }
  }, [reportList, scopeDetails, location]);

  const tooltipComponent = (menu, isPromotable) => {
    const message = isPromotable ? "Those who purchased this system also purchased this feature. It's highly recommended." : "You have not subscribed to this feature. Please see our pricing plan.";
    return (
      <Tooltip placement="right" overlayClassName="custom-tooltip" title={message}>
        <div className="locked-menu" style={{ opacity: 1 }}>
          <p>
            <span>{menu.title}</span>
          </p>
          {isPromotable && <img src={recommendationIcon} alt="Recommended" style={{ maxWidth: "25px" }} />}
        </div>
      </Tooltip>
    );
  };

  const validatedMenu = (menu, isSubscribed, permissions = []) => {
    return isSubscribed && permissions.length > 0
      ? menu
      : {
          icon: <div style={{ opacity: 0.4 }}>{menu.icon}</div>,
          menuTitle: (
            <Tooltip overlayClassName="custom-tooltip" placement="right" title="You don't have access to this feature. Please contact your administrator.">
              <div className="locked-menu">
                <p>{menu.title}</p> <Icon name="locked" size={16} />
              </div>
            </Tooltip>
          ),
        };
  };

  const generateMenus = (menus) => {
    let generatedMenus = [];
    const filteredMenus = menus.filter((item) => {
      return slugify(item.name) === MENU_NAMES.HOME || slugify(item.name) === MENU_NAMES.DASHBOARD || slugify(item.name) === MENU_NAMES.ONBOARDING || slugify(item.name) === MENU_NAMES.REPORTS || slugify(item.name) === MENU_NAMES.LOGOUT;
    });

    filteredMenus &&
      filteredMenus.length > 0 &&
      filteredMenus.map((menu, index) => {
        if (menu.isSubscribed || (!menu.isSubscribed && menu.isPromotable)) {
          let menuOBJ = {
            key: index,
            icon: menuItems(slugify(menu.name), menu.isSubscribed, menu.isPromotable).icon,
            label: menuItems(slugify(menu.name), menu.isSubscribed, menu.isPromotable).menuTitle,
            onClick: menuItems(slugify(menu.name), menu.isSubscribed, menu.isPromotable).onClick
              ? menuItems(slugify(menu.name), menu.isSubscribed, menu.isPromotable).onClick
              : () => {
                  localStorage.setItem("mainModuleID", menu.mainModuleID ? menu.mainModuleID : "");
                },
          };

          if (menu.hasOwnProperty("subMenus")) {
            let submenus = [];
            const filteredsub = menu.subMenus.filter((item) => {
              return item.ref === SCOPE_REFERENCES.VIEW_APPLICATION || item.category === "REPORT_LIST";
            });
            filteredsub.map((sub, subIndex) => {
              if (sub.hasOwnProperty("category") && sub.category === MENU_NAMES.REPORT_LIST) {
                let submenu = {
                  key: `${index}.${subIndex}`,
                  icon: menuItems(slugify(MENU_NAMES.REPORT_LIST), sub.isSubscribed, sub.isPromotable, sub.permissions, sub).icon,
                  label: menuItems(slugify(MENU_NAMES.REPORT_LIST), sub.isSubscribed, sub.isPromotable, sub.permissions, sub).menuTitle,
                  onClick: () => {
                    localStorage.setItem("subModuleID", sub.subModuleID ? sub.subModuleID : "");
                  },
                };

                submenus.push(submenu);
              } else {
                let submenu = {
                  key: `${index}.${subIndex}`,
                  icon: menuItems(slugify(sub.name), sub.isSubscribed, sub.isPromotable, sub.permissions).icon,
                  label: menuItems(slugify(sub.name), sub.isSubscribed, sub.isPromotable, sub.permissions).menuTitle,
                  onClick: () => {
                    localStorage.setItem("subModuleID", sub.subModuleID ? sub.subModuleID : "");
                  },
                };
                submenus.push(submenu);
              }
            });

            menuOBJ.children = submenus;
          }

          generatedMenus.push(menuOBJ);
        }
      });

    setMenus(generatedMenus);
  };

  const generateLocationSelectorDetails = (dashboardSliceState) => {
    const { currentlySelectedOrgIndex, currentlySelectedCompanyIndex, currentlySelectedLocationIndex, fullUserOrgDetailsList, getFullUserOrgDetailsByUserIdReqStatus } = dashboardSliceState;

    const { availableLocations } = fullUserOrgDetailsList;
    const orgOutput = fullUserOrgDetailsList.map((org) => {
      const filteredItems =
        availableLocations &&
        availableLocations.filter((item) => {
          return item.OrganizationName === org.OrganizationData.Name;
        });

      return {
        label: (org && org.OrganizationData.Name) || "",
        options: filteredItems.map((location) => {
          return {
            icon: "marker-place",
            label: location && location.LocationName,
            value: location && location.LocationID,
            organizationDetails: org.OrganizationData,
            // companyDetails: location.companyDetails,
            locationDetails: location,
          };
        }),
      };
    });
    return orgOutput;
  };

  const handleLocationChange = (options = {}) => {
    const { clickedLocationData = {}, dispatch } = options;
    const { organizationDetails, companyDetails, locationDetails } = clickedLocationData;
    dispatch(
      setLocationSelectorData({
        // orgIndex: organizationDetails.OrganizationIndex,
        orgId: organizationDetails.OrganisationID,
        // comIndex: companyDetails.CompanyIndex,
        // comId: companyDetails.CompanyID,
        orgName: organizationDetails.Name,
        orgName: organizationDetails.Name,
        locDetails: locationDetails,
        locId: locationDetails.LocationID,
        locName: locationDetails.LocationName,
      })
    );
  };

  useEffect(() => {
    if (dashboardSliceState.getFullUserOrgDetailsByUserIdReqStatus === "SUCCEEDED") {
      const locations = generateLocationSelectorDetails(dashboardSliceState);
      locations && setLocationSelectorDetails(locations);
    }
  }, [dashboardSliceState.getFullUserOrgDetailsByUserIdReqStatus]);

  useEffect(() => {
    const storingToken = async () => {
      const userToken = await generateToken(false);
      const jsonString = JSON.stringify(userToken);
      localStorage.setItem("userToken", jsonString);
      dispatch(fetchTokenDetails(localStorage.getItem("userToken")));
    };
    storingToken();
  }, []);

  useEffect(() => {
    dispatch(
      fetchUserDetailsById({
        orgId: fromStorage("user", "OrganizationID"),
        userId: getLoggedUserId(),
      })
    );
    dispatch(fetchUserDataById(getLoggedUserId()));
  }, []);

  const generatePageTitleDetails = (dashboardSliceState) => {
    const { currentlySelectedOrgIndex, currentlySelectedCompanyIndex, currentlySelectedLocationIndex, fullUserOrgDetailsList, getFullUserOrgDetailsByUserIdReqStatus } = dashboardSliceState;

    if (getFullUserOrgDetailsByUserIdReqStatus !== reqStatusTypes.succeeded) {
      return {};
    }
    return {
      pageTitle: fullUserOrgDetailsList["availableLocations"].length > 0 ? fullUserOrgDetailsList[0].OrganizationData.Name : null,
      pageSubtitle: fullUserOrgDetailsList["availableLocations"] ? fullUserOrgDetailsList["availableLocations"][0]?.LocationName : null,
    };
  };

  useEffect(() => {
    retrievePermissions();
  }, [dashboardSliceState.currentlySelectedOrgId]);

  const pageTitleDetails = generatePageTitleDetails(dashboardSliceState);

  const generateCurrentBreadcrumbPath = (currentPath) => {
    const splittedPathname = currentPath.split("/").filter((item) => Boolean(item)); // Removing empty item coming from "/" split.

    return splittedPathname.map((item, index) => {
      const isHome = `/${item}` === routePaths.dashboardRoot;
      const linkPath = `/${splittedPathname.slice(0, index + 1).join("/")}/`; // Path as ReactRouter URL.
      const pageTitle = item.includes("-") ? item.replace("-", " ") : item;

      return {
        icon: isHome ? "home" : "",
        title: pageTitle?.toUpperCase(),
        onClick: () => {
          navigate(linkPath);
        },
        isActive: index === splittedPathname.length - 1,
      };
    });
  };

  const toggleMenu = () => {
    setMenuState(!menuState);
  };

  const retrievePermissions = async () => {
    const request = {
      orgID: fromStorage("user", "OrganizationID"),
      appID: fromStorage("user", "VerticalApplicationID"),
      userID: fromStorage("user", "userid"),
    };
    const scope = await getScopePermissions(request);

    setScopeDetails(scope);
  };

  return (
    <GlobalLayout>
      <SideMenuWithToggle
        items={menus}
        // logoUrl={menuState ? logoWithBlueBackground : logoWithText}
        collapsed={menuState}
      />

      <div className="eco-layout__content">
        <GlobalHeader
          type="complex"
          helpMenuContent={[
            {
              key: 1,
              icon: "light",
              content: "What’s new in the product?",
              onClick: () => {
                alert("Guidline 1");
              },
            },
            {
              key: 2,
              icon: "mission",
              content: "How to set targets?",
              onClick: () => {
                alert("Guidline 2");
              },
            },
            {
              key: 3,
              icon: "news",
              content: "New Releases",
              onClick: () => {
                alert("Guidline 3");
              },
            },
            {
              key: 4,
              icon: "settings",
              content: "More about product setting?",
              onClick: () => {
                alert("Guidline 4");
              },
            },
          ]}
          profileMenuContent={[
            {
              key: 1,
              icon: "user",
              content: "Profile",
              onClick: () => {
                alert("Profile");
              },
            },
            {
              key: 2,
              icon: "settings",
              content: "Settings",
              onClick: () => {
                alert("Settings");
              },
            },
            {
              key: 3,
              icon: "logout",
              content: "Logout",
              onClick: () => {
                sessionStorage.clear();
                logoutUser({
                  redirectTo: routePaths.loginRoot,
                });
              },
            },
          ]}
          pageTitleProps={{
            pageTitle: pageTitleDetails.pageTitle,
            pageSubtitle: pageTitleDetails.pageSubtitle,
          }}
          breadcrumbProperties={{
            breadcrumbRoutes: generateCurrentBreadcrumbPath(location.pathname),
          }}
          locationSelectorProps={
            locationSelectorDetails && {
              // locationAvatarValue: '',
              groupedItems: locationSelectorDetails,
              handleLocationChange: (e) => {
                setSelectedLocation(e);
                // this.setState({
                // 	selectedLocation: e
                // })
                handleLocationChange({
                  clickedLocationData: e,
                  dispatch: dispatch,
                  // storesList: dashboardSliceState.storesList
                });
              },

              defaultValue: selectedLocation ? selectedLocation : locationSelectorDetails[0].options[0],
            }
          }
          onToggle={toggleMenu}
          hasIcon={true}
        />
        <div className={menuState ? "eco-app-content not-expanded" : "eco-app-content expanded"}>
          <Outlet />
        </div>
        <GlobalFooter productVersion={process.env.REACT_APP_CURRENT_VERSION} />
      </div>
    </GlobalLayout>
  );
}

export default Dashboard;
