import axios from "axios";
import apiEndpointPaths from "../apiEndpointPaths";
import { generateToken } from "../apiRequests/tokenGenerators";
import { handleTokenExpirationApiProblem } from "../helpers";
import { APIError } from "../errorHandlers";
import { errorLogger } from "../errorHandlers";

export async function loginUser(reqBody = {}, options = {}) {
  try {
    const { UserName, Password, AppID } = reqBody;
    const data = {
      UserName,
      Password,
      AppID,
    };
    const token = await generateToken(true);

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(`${apiEndpointPaths.witmegOrgServer.loginUser}`, data, options);

    const { Status, Result, OrgManagementData } = resObj.data;

    if (!Status) {
      handleTokenExpirationApiProblem(resObj);
      throw new APIError(Result);
    }

    return { Status: true, result: Result, organization: OrgManagementData };
  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("mongo: no documents in result"): {
          errorMessage = "No account exist for this username. Please check again.";
          break;
        }

        case errMsg.includes("Password does not match"): {
          errorMessage = "Your password is incorrect. Please check again.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    // errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(errorMessage, error));
  }
}

export async function getFullUserOrgDetailsByUserId(reqBody = {}, options = {}) {
  try {
    const token = await generateToken(true);

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(`${apiEndpointPaths.witmegOrgServer.getFullUserOrgDetailsByUserId}`, reqBody, options);
    return resObj.data;
  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("encoding/hex: invalid byte"):
        case errMsg.includes("the provided hex string is not a valid ObjectID"):
        case errMsg.includes("encoding/hex: odd length hex string"): {
          errorMessage = "Invalid UserID.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    // errorLogger(error, { customErrMsg: errorMessage });
    return Promise.reject(APIError(errorMessage, error));
  }
}

export async function getUserDetailsByUserId(reqBody = {}, options = {}) {
  try {
    const { UserID } = reqBody;

    const data = {};

    const token = await generateToken(true);

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.get(`${apiEndpointPaths.witmegOrgServer.getUserDetailsByUserId}/${UserID}`, options);

    const { Status, Result } = resObj.data;

    if (!Status) {
      handleTokenExpirationApiProblem(resObj);
      throw new APIError(Result);
    }

    return Result;
  } catch (error) {
    const errMsg = error.message;
    let customErrMsg = error.customErrMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("encoding/hex: invalid byte"):
        case errMsg.includes("the provided hex string is not a valid ObjectID"):
        case errMsg.includes("encoding/hex: odd length hex string"): {
          customErrMsg = "Invalid UserID.";
          break;
        }

        case errMsg.includes("Incorrect Path / values"): {
          customErrMsg = "UserID not provided.";
          break;
        }

        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

export async function getPermissions(reqBody = {}, options = {}) {
  try {
    const API_URL = apiEndpointPaths.witmegOrgServer.getPermissions;

    const token = await generateToken(true);

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(API_URL, reqBody, options);

    const { Status, Result } = resObj.data;

    if (!Status) {
      throw new APIError(Result);
    }

    return resObj.data;
  } catch (error) {
    const errMsg = error.message;
    let customErrMsg = error.customErrMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}
