import React, { useState, useEffect } from "react";
import { Dashboard } from "witmeg-reporting-embed";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function MainReportsPage() {
  const { id } = useParams();
  //console.log("params", params);
  let user = localStorage.getItem("user");
  let AccessToken = JSON.parse(user).AccessToken;
  let userId = JSON.parse(user).userid;
  let dashboardId = id;
  const dashboardSliceState = useSelector((state) => state.dashboard);

  const [currentlySelectedOrgDataObj, setCurrentlySelectedOrgDataObj] = useState({});
  const [currentlySelectedLocationDataObj, setCurrentlySelectedLocationDataObj] = useState({});

  return (
    <div style={{ height: "5000px", padding: "16px" }}>
      {AccessToken && id && dashboardId && AccessToken && dashboardSliceState && dashboardSliceState?.fullUserOrgDetailsList ? (
        <Dashboard
          baseUrl={`${process.env.REACT_APP_REPORTING_BASE_URL}/dashboard-details`}
          token={AccessToken}
          dashboardId={dashboardId}
          organizationId={dashboardSliceState.currentlySelectedOrgId}
          cloudLocationId={dashboardSliceState.currentlySelectedLocationId}
          userId={userId}
          userName={dashboardSliceState?.fullUserDetails?.UserName}
          organizationName={dashboardSliceState?.fullUserOrgDetailsList[dashboardSliceState?.currentlySelectedOrgIndex]?.OrganizationName}
          locationName={dashboardSliceState && dashboardSliceState.currentlySelectedLocationName}
        />
      ) : null}
    </div>
  );
}

export default MainReportsPage;
