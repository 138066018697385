import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Stack, StackItem, Button, Table, Space, InputGroup, SearchableMenu, RightPanelHeader, RightPanel, Accordion, Checkbox, Popover, ActionList, Icon, Alert, Typography, Popup, Steps, FileUploader, InlineMessage, Message, Label, DatePicker, Spin, ConfirmationPopup, Switch, Pagination, Select, InputBox, TextArea } from "witmeg-ui-system";
import { getTermsAndConditions, getFormDetailsByFormID, dynamicDataAccess } from "../../utilities/apiRequests/witmegDynamicServerRegistry";
import moment from "moment";

function TermsAndConditions() {
  const navigate = useNavigate();
  const location = useLocation();
  const dashboardSliceState = useSelector((state) => state.dashboard);
  const [formID, setformID] = useState(location.state.WorkFlow.ModuleID);
  const [htmlContent, setHtmlContent] = useState();
  const [showOopsPopUp, setShowOopsPopUp] = useState(false);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [formData, setFormData] = useState([]);
  const [failPopUpData, setFailPopUpData] = useState();
  const [successPopUpData, setSuccessPopUpData] = useState();
  const loadData = location.state.Result;

  console.log("loadData", loadData);

  const [apiData, setApiData] = useState({
    termsConditions: {
      displayName: "",
    },
    buttonOne: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
    buttonTwo: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
  });

  const fetchTermsAndConditions = async () => {
    const result = await getTermsAndConditions({
      ApplicationID: process.env.REACT_APP_APPLICATION_ID,
      FormID: formID,
    });

    if (result && Object.keys(result).length > 0) {
      setHtmlContent(result.HTMLContent);
    }
  };

  const getFormattedAPIData = (result) => {
    setFormData(result);
    const updatedTermsConditions = {
      displayName: `${result.Widgets[0].DisplayName}`,
      date: moment(result.Widgets[0].CreatedDate).format("MMMM DD, YYYY"),
    };

    const updatedButtonOne = {
      displayName: `${result.Widgets[1].DisplayName}`,
    };

    const updatedButtonTwo = {
      displayName: `${result.Widgets[2].DisplayName}`,
      apiEndPoint: `${result.Widgets[2].Action.APIConfig.BasePath}${result.Widgets[2].Action.APIConfig.Route}`,
      method: `${result.Widgets[2].Action.APIConfig.APIMethod}`,
      authType: `${result.Widgets[2].Action.APIConfig.AuthType}`,
      actionType: `${result.Widgets[2].Action.ActionType}`,
      eventType: `${result.Widgets[2].Action.EventType}`,
      widgetID: `${result.Widgets[2].Id}`,
      apiID: `${result.Widgets[2].Action.APIConfig.APIID}`,
    };

    // const updatedButtonTwo = {
    //   apiEndPoint: `${result.Widgets[0].Action.APIConfig.BasePath}${result.Widgets[0].Action.APIConfig.Route}`,
    //   method: `${result.Widgets[0].Action.APIConfig.APIMethod}`,
    //   authType: `${result.Widgets[0].Action.APIConfig.AuthType}`,
    //   actionType: `${result.Widgets[0].Action.ActionType}`,
    //   eventType: `${result.Widgets[0].Action.EventType}`,
    //   widgetID: `${result.Widgets[0].Id}`,
    //   apiID: `${result.Widgets[0].Action.APIConfig.APIID}`,
    // };

    setApiData((prevState) => ({
      ...prevState,
      termsConditions: {
        ...updatedTermsConditions,
      },
      buttonOne: {
        ...updatedButtonOne,
      },
      buttonTwo: {
        ...updatedButtonTwo,
      },
    }));
  };

  const fetchData = async () => {
    const result = await getFormDetailsByFormID({
      ApplicationID: process.env.REACT_APP_APPLICATION_ID,
      FormID: formID,
    });
    if (result && Object.keys(result).length > 0) {
      await getFormattedAPIData(result);
    }
  };

  useEffect(() => {
    if (dashboardSliceState && dashboardSliceState.currentlySelectedOrgId && formID !== "") {
      fetchData();
      fetchTermsAndConditions();
    }
  }, [dashboardSliceState, formID]);

  const handleSecondButtonClick = async () => {
    const result = await dynamicDataAccess(
      {
        ID: "",
        FormSubmissionNo: "",
        TemplateFormDetails: {
          ID: formData.TemplateID,
          ApplicationID: formData.ApplicationID,
          FormID: formData.FormID,
          VersionNo: formData.Version,
          ActionType: apiData.buttonTwo.actionType,
          EventType: apiData.buttonTwo.eventType,
          WidgetID: apiData.buttonTwo.widgetID,
          APIID: apiData.buttonTwo.apiID,
        },
        PayLoad: [
          {
            Key: "id",
            Value: loadData.Main_Legal_EntityID,
            Type: "STRING",
          },
        ],
        SubmittedBy: formData.CreatedBy,
        CreatedDate: formData.CreatedDate,
        ModifiedDate: formData.ModifiedDate,
        IsDeleted: false,
      },
      {
        method: apiData.buttonTwo.method,
        url: apiData.buttonTwo.apiEndPoint,
      }
    );
    if (result.Status) {
      setShowSuccessPopUp(true);
      setSuccessPopUpData(result);
    } else {
      setShowOopsPopUp(true);
      setFailPopUpData(result);
    }
  };

  return (
    <div className="application-page-content" style={{ padding: "20px" }}>
      <div className="customer-page-content" style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
        <div style={{ display: "flex", width: "786px", height: "852px", borderRadius: "4px", background: "rgba(196, 196, 196, 0.05)", border: " 1px solid var(--black-95, #F2F2F2)", flexDirection: "column", alignItems: "center" }}>
          <div style={{ display: "flex", marginTop: "20px", width: "90%", flexDirection: "column" }}>
            <Typography type="title" level={3} className="eco-mb-12" pattern="bold">
              {apiData.termsConditions.displayName}
            </Typography>
            <Label>Last updated {apiData.termsConditions.date}</Label>
          </div>
          <div className="html-content-container" style={{ height: "587px", borderRadius: "8px", border: "1px solid rgba(0, 0, 0, 0.10)", background: "#FFF", width: "90%", marginTop: "40px", overflow: "auto" }}>
            <div style={{ padding: "30px" }} dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
          <div style={{ display: "flex", margin: "40px  40px", alignSelf: "flex-end", width: "35%", justifyContent: "space-around" }}>
            <Button variant="primary" onClick={() => navigate("/paymentOnboarding/view-Application")}>
              {apiData.buttonOne.displayName}
            </Button>
            <Button variant="secondary" onClick={handleSecondButtonClick}>
              {apiData.buttonTwo.displayName}
            </Button>
          </div>
        </div>
      </div>

      <Popup
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ fontSize: "16px" }}>Oops! Something Went Wrong.</div>
          </div>
        }
        centered
        open={showOopsPopUp}
        onCancel={() => setShowOopsPopUp(false)}
        closable={true}
        width={560}
      >
        <div>
          {failPopUpData && failPopUpData.Result}
          <div style={{ display: "flex", margin: "20px 50px 0px 0px ", width: "100%", justifyContent: "flex-end" }}>
            <Button variant="primary" onClick={() => navigate(failPopUpData.WorkFlow.HyperLink)}>
              Return to previous page
            </Button>
          </div>
        </div>
      </Popup>

      <Popup
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Icon name="check-circle" size={24} color="green" className="eco-mr-8"></Icon>
            </div>
            <div style={{ fontSize: "16px" }}>The Future Looks Bright with You Here!</div>
          </div>
        }
        centered
        open={showSuccessPopUp}
        onCancel={() => setShowSuccessPopUp(false)}
        closable={true}
        width={410}
      >
        <div>
          Thank you for joining us! Your account has been successfully initiated.
          <div style={{ display: "flex", margin: "20px 50px 0px 0px ", width: "100%", justifyContent: "flex-end" }}>
            <Button variant="primary" onClick={() => navigate(successPopUpData.WorkFlow.HyperLink)}>
              Go to Dashboard
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default TermsAndConditions;
