import React, { useState } from 'react'
import loginImage from '../../assets/loginpage-image.png'
import {
    Button,
    InputBox,
    Stack,
    StackItem,
    Typography,
    Form,
    Spin,
    Alert,
    Message,
} from "witmeg-ui-system";
import "./index.css";
import { verifyAuthentication } from "../../utilities/helpers";
import { loginUser } from '../../utilities/apiRequests/witmegOrgServerRequests';


function LoginPage() {
    const { REACT_APP_APPLICATION_ID } = process.env;
    const [loginInfo, setLoginInfo] = useState({
        username: "",
        password: "",
    });
    const [loginInfoValidationErrors, setLoginInfoValidationErrors] = useState({
        username: false,
        password: false,
    });
    const [formSubmitError, setFormSubmitError] = useState(null);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const handleChangeInput = (e, fieldName) => {
        setLoginInfo({
            ...loginInfo,
            [fieldName]: e.target.value,
        })
    }

    const login = async () => {
        const hasValidationIssues = validateLoginInfo();
        if (!hasValidationIssues) {
            setFormSubmitError(null);
            const apiReqBody = {
                UserName: loginInfo.username,
                Password: loginInfo.password,
                AppID: REACT_APP_APPLICATION_ID,
            };

            setIsFormSubmitting(true);

            try {
                const resData = await loginUser(apiReqBody);
                verifyAuthentication(resData);

            } catch (error) {
                const errMsg = error.customErrMsg || error.message;
                setIsFormSubmitting(false);
                setFormSubmitError(errMsg);
            }
        }
    };


    const validateLoginInfo = () => {
        const loginInfoValidations = {};
        if (loginInfo.username === "") {
            loginInfoValidations.username = true;
        }
        if (loginInfo.password === "") {
            loginInfoValidations.password = true;
        }
        setLoginInfoValidationErrors(loginInfoValidations);

        return loginInfoValidations.username || loginInfoValidations.password;
    };


    return (
        <Stack className="login-page" horizontalAlign="h_center">
            <Stack className="login-page-container" direction="row">
                <StackItem className="login-page-image-container">
                    <Stack className="login-page-image-container-content">
                        <StackItem className="eco-mt-32">
                            {/* <img src={loginImage} /> */}
                        </StackItem>
                        <StackItem className="eco-mt-40">
                            <Typography
                                type="title"
                                level={1}
                                pattern="bold"
                                alignment="left"
                                color="white_base"
                            >
                                Payment <br />
                                Onboarding
                            </Typography>
                        </StackItem>
                        <StackItem className="eco-mt-16">
                            <Typography
                                size="medium"
                                pattern="bold"
                                alignment="left"
                                color="white_base"
                            >
                                Get started with Rewardstick Pays.
                            </Typography>
                        </StackItem>
                    </Stack>
                </StackItem>
                <StackItem className="login-page-form-container">
                    <Stack
                        className="login-page-form"
                        direction="column"
                        horizontalAlign="h_start"
                    >
                        <StackItem>
                            {/* <img src={logoWithBlueBackground} /> */}
                        </StackItem>
                        <StackItem className="login-form-elements-mt">
                            <Typography
                                type="title"
                                level={1}
                                pattern="bold"
                                alignment="left"
                            >
                                Sign In
                            </Typography>
                        </StackItem>
                        <StackItem className="login-form-elements-mt">
                            <Typography
                                size="small"
                                pattern="bold"
                                alignment="left"
                            >
                                Sign in to your account to get started.
                            </Typography>
                        </StackItem>
                        <StackItem className="login-form-elements-mt">
                            <InputBox
                                iconName="email"
                                type="text"
                                placeholder="Your email"
                                onChange={(e) => {
                                    handleChangeInput(e, "username");
                                }}
                            // error={loginInfoValidationErrors.username}
                            />
                        </StackItem>
                        <StackItem>
                            <InputBox
                                iconName="locked"
                                type="password"
                                placeholder="Password"
                                onChange={(e) => {
                                    handleChangeInput(e, "password");
                                }}
                            // error={loginInfoValidationErrors.password}
                            />
                        </StackItem>
                        <StackItem className="eco-mt-8">
                            <Button
                                category="text"
                                color="blue"
                                variant="primary"
                                fullWidth={true}
                                onClick={login}
                            >
                                Sign in
                            </Button>
                        </StackItem>
                        {/* {isFormSubmitting && <Spin className="eco-mt-16" />}
                        {formSubmitError && (
                            <Alert
                                className="eco-mt-16"
                                message={formSubmitError}
                                type="error"
                            />
                        )} */}
                        <StackItem className="login-form-elements-mt">
                            <Typography size="small" alignment="left">
                                By creating your account you agree with our{" "}
                                <a style={{ color: '#5352AF' }}>Terms and Conditions</a> and{" "}
                                <a style={{ color: '#5352AF' }}>Privacy Policy</a>
                            </Typography>
                        </StackItem>
                        <StackItem className="login-form-elements-mt">
                            <Typography size="small" alignment="left">
                                Don’t have an account?{" "}
                                <Typography
                                    size="small"
                                    pattern="bold"
                                    alignment="left"
                                    style={{ color: '#5352AF' }}
                                >
                                    Sign up
                                </Typography>
                            </Typography>
                        </StackItem>
                    </Stack>
                </StackItem>
            </Stack >
        </Stack >
    )
}

export default LoginPage