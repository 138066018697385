import axios from 'axios';
import {
    checkIsUserLoggedIn,
    getLoggedUserDetails,
} from '../userAuthentication';
import { globalV } from '../../configs/globalValues';

const {
    WITMEG_ORGSERVER___BASEURL,
    WITMEG_APPSERVER___BASEURL,
    REACT_APP__PAYMENTMODULESERVER___BASEURL,
    TOKEN_SERVICE_BASE_URL

} = globalV.baseURLS



export async function generateToken(isLoginServerRequest) {
    let serviceName = "default"
    try {
        // const isLoggedUser = false;
        const isLoggedUser = checkIsUserLoggedIn();
        let usertoken;
        if (isLoggedUser) {
            usertoken = getLoggedUserDetails().AccessToken;
        }
        const tokenResponse = await axios.post(`${globalV.baseURLS.TOKEN_SERVICE_BASE_URL}/tokenservice`, { usertoken, isLoginServerRequest, serviceName });
        return tokenResponse.data.token;
    } catch (error) {
        console.log('Error in generating token', error);
    }
}