import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from './dashboardSlice'
import tokenSlice from "./tokenSlice";


export default configureStore({
    reducer: {
        dashboard: dashboardReducer,
        tokenSlice: tokenSlice,
    }
});