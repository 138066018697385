export const ENTITLEMENT_STATUS = {
  ACTIVE: "active",
  PAYMENT_FAILED: "payment_failed",
  AWAITING_SUSPENSION: "awaiting_suspension",
  ACCOUNT_SUSPENDED: "account_suspended",
  ACCOUNT_DELETED: "account_deleted",
};

export const USER_TYPES = {
  ADMIN: "ADMIN",
  PRIMARY: "PRIMARY",
  STANDARD: "STANDARD",
  GUEST: "GUEST",
};

export const reqStatusTypes = {
  idle: "IDLE",
  pending: "PENDING",
  succeeded: "SUCCEEDED",
  failed: "FAILED",
};

export const SCOPE_TYPES = {
  FEATURE: "FEATURE",
  MAIN_MENU: "MAIN_MENU",
  SUB_MENU: "SUB_MENU",
};

export const PERMISSION_TYPES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
  VIEW: "VIEW",
  UPLOAD: "UPLOAD",
  DOWNLOAD: "DOWNLOAD",
  ACCESS: "ACCESS",
};

export const SCOPE_REFERENCES = {
  DASHBOARD: "64f557efe4f8b66a82093311",

  //Onboarding
  VIEW_APPLICATION: "65bb32344d25088b86737540",

  //Reports
  REPORTS: "65c090a56df850a78f47b584",
};

export const MENU_NAMES = {
  HOME: "HOME",
  LOGIN: "LOGIN",
  AUTH: "AUTH",
  LOGOUT: "LOGOUT",
  DASHBOARD: "DASHBOARD",

  ONBOARDING: "ONBOARDING",
  VIEW_APPLICATION: "VIEW_APPLICATION",
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",

  REPORTS: "REPORTS",
  REPORT_LIST: "REPORT_LIST",
};
