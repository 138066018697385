
import { generateToken } from "../utilities/apiRequests/tokenGenerators";

export function APIError(
    errorMessage = 'Error Occurred. Please try again.',
    orgError = '',
    otherDetails = {}
) {

    const error = new Error(orgError || errorMessage);

    error.type = 'APIERROR';
    error.message = errorMessage;

    handleNetworkError(orgError);
    forceLogout(orgError?.response);
    return error;
}

async function handleNetworkError(error) {
    if (error.code === 'ERR_NETWORK') {
        //default token
        const userToken = await generateToken(false);
        const jsonString = JSON.stringify(userToken);
        localStorage.setItem('userToken', jsonString);
        //  configureStore.dispatch(fetchTokenDetails(userToken))
        //image token
        // const imageToken = await generateEcoServiceServerToken(false);
        // browserStorage.set('imageToken', imageToken);
        // configureStore.dispatch(fetchEcoServiceTokenDetails(browserStorage.get('imageToken')))

    }
}

function forceLogout(response) {
    //console.log("response", response);
    if (response?.data?.message === "JWT validation failed: TIME_CONSTRAINT_FAILURE") {
        // configureStore.dispatch(setHastokenExpiredError({ hastokenExpiredError: true }));
    } else {
        console.log('stay user');
    }
}

export function errorLogger(error, options = {}) {
    //console.customLog('DEV', new Error(error), options);
}