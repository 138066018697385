import { APIError, errorLogger } from "../errorHandlers";

import apiEndpoints from "../apiEndpointPaths";
import axios from "axios";

export async function getReportListByAppIDAndOrgID(reqBody = {}, options = {}) {
  try {
    const apiReqBody = {};

    const { OrgID, appId } = reqBody;
    let reqToken = JSON.parse(localStorage.getItem("user")).AccessToken;

    //console.log("reqToken ===>>", reqToken)

    const apiReqUrl = `${apiEndpoints.witmegReportingServer.getReportListByAppIDAndOrgID}?orgId=${OrgID}&appId=${appId}`;

    const resObj = await axios({
      url: apiReqUrl,
      method: "GET",
      headers: {
        Authorization: `Bearer ${reqToken}`,
      },
      data: apiReqBody,
      ...options,
    });

    const { status, data } = resObj.data;

    if (!status) {
      throw new APIError(data);
    }

    return data; //.filter(rli => rli.resources.find(r => r.type !== "Combined Resource"));;
  } catch (error) {
    // const errMsg = error.message;
    let customErrMsg = error.customErrMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    // return Promise.reject(APIError(customErrMsg, error));
  }
}
