import axios from "axios";
import apiEndpointPaths from "../apiEndpointPaths";
import { generateToken } from "../apiRequests/tokenGenerators";
import { handleTokenExpirationApiProblem } from "../helpers";
import { APIError } from "../errorHandlers";
import { errorLogger } from "../errorHandlers";

export async function getFormDetailsByModuleID(reqBody = {}, options = {}) {
  try {
    const options = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(apiEndpointPaths.witmegPaymentServer.getFormDetailsByModuleID, reqBody, options);

    const { Status, Result } = resObj.data;

    if (!Status) {
      handleTokenExpirationApiProblem(resObj);
      throw new APIError(Result);
    }

    return Result;
  } catch (error) {
    const errorMessage = error.message;

    // errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(errorMessage, error));
  }
}

export async function getFormDetailsByFormID(reqBody = {}, options = {}) {
  try {
    // const token = await generateToken(true);

    const options = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(apiEndpointPaths.witmegPaymentServer.getFormDetailsByFormID, reqBody, options);

    const { Status, Result } = resObj.data;

    if (!Status) {
      handleTokenExpirationApiProblem(resObj);
      throw new APIError(Result);
    }

    return Result;
  } catch (error) {
    const errorMessage = error.message;

    // errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(errorMessage, error));
  }
}

export async function dynamicDataAccess(reqBody = {}, opt = {}) {
  try {
    if (!opt || !opt.method || !opt.url) {
      throw new Error("Missing required options (method, url)");
    }

    const options = {
      method: opt.method,
      url: opt.url,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      data: reqBody,
    };

    const response = await axios(options);
    const { Status, Result, WorkFlow, ProcessStages, AccountHolder, OnBoardingURL } = response.data;

    // if (!Status) {
    //   handleTokenExpirationApiProblem(response);
    //   throw new APIError(Result);
    // }
    return { Status, Result, WorkFlow, ProcessStages, AccountHolder, OnBoardingURL };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error);
    } else {
      const errorMessage = error.message;
      return Promise.reject(APIError(errorMessage, error));
    }
  }
}

//getDialingCode
export async function getDialingCode(reqBody = {}, options = {}) {
  try {
    // const token = await generateToken(true);

    const options = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(apiEndpointPaths.witmegPaymentServer.getDialingCode, reqBody, options);

    const { Status, Result } = resObj.data;

    if (!Status) {
      handleTokenExpirationApiProblem(resObj);
      throw new APIError(Result);
    }

    return Result;
  } catch (error) {
    const errorMessage = error.message;

    // errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(errorMessage, error));
  }
}

//getTermsAndConditions
export async function getTermsAndConditions(reqBody = {}, options = {}) {
  try {
    // const token = await generateToken(true);

    const options = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.get(apiEndpointPaths.witmegPaymentServer.getTermsAndConditions, reqBody, options);

    const { Status, Result } = resObj.data;

    if (!Status) {
      handleTokenExpirationApiProblem(resObj);
      throw new APIError(Result);
    }

    return Result;
  } catch (error) {
    const errorMessage = error.message;

    // errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(errorMessage, error));
  }
}
