import React, { useState, useEffect } from "react";
import { Stack, StackItem, Button, Table, Space, InputGroup, SearchableMenu, RightPanelHeader, RightPanel, Accordion, Checkbox, Popover, ActionList, Icon, Alert, Typography, Popup, Steps, FileUploader, InlineMessage, Message, Label, DatePicker, Spin, ConfirmationPopup, Switch, Pagination, Select, InputBox, TextArea } from "witmeg-ui-system";
import { useLocation, useNavigate } from "react-router-dom";

function OnboardingStatus(props) {
  const navigate = useNavigate();
  const { loadData, onboardingStatusData, proceedStatus, setShowStatusPopup } = props;
  const [onboardData, setOnboardData] = useState([
    { key: 0, Process: "Main Legal Entity Creation" },
    { key: 1, Process: "Individual Entity Creation" },
    { key: 2, Process: "Entity Association Stage" },
    { key: 3, Process: "Account Holder Stage" },
    { key: 4, Process: "BusinessLine Stage" },
    { key: 5, Process: "Store Creation Stage" },
    { key: 6, Process: "Hosted OnboardingURL Creation Stage" },
  ]);

  useEffect(() => {
    if (onboardData && onboardData.length > 0) {
      const finalOnboardDetails = onboardData?.map((data) => {
        const matchedItem = onboardingStatusData?.ProcessStages?.find((item) => item.Process.split("_").join(" ") === data.Process);
        return {
          ...data,
          Error: matchedItem == undefined ? "White Card" : matchedItem.Error === "" ? "No Error" : "Yes Error",
          Process: data.Process,
          Result: matchedItem ? matchedItem.Result : "",
          ErrorMessage: false,
        };
      });
      setOnboardData(finalOnboardDetails);
    }
  }, [onboardingStatusData]);

  console.log("onboardingStatusData", onboardingStatusData);

  console.log("onboardData", onboardData);

  const handleProceed = () => {
    window.open(onboardingStatusData.Result, "_blank", "noopener,noreferrer");
    navigate(`${onboardingStatusData.WorkFlow.HyperLink}`);
  };

  const handleErrorClick = (e, record) => {
    setOnboardData(
      onboardData.map((item) => {
        return {
          ...item,
          ErrorMessage: record.key === item.key ? !item.ErrorMessage : false,
        };
      })
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "550px" }}>
      <div style={{ width: "380px", height: "600px", padding: "20px", borderRadius: "5px", marginTop: "15px" }}>
        {onboardData &&
          onboardData.length > 0 &&
          onboardData.map((item) =>
            item.Error === "White Card" ? (
              <div style={{ borderRadius: "5px", border: "1px solid #999999", width: "100%", display: "flex", padding: "20px", alignItems: "center", height: "50px", marginBottom: "10px" }} onClick={() => {}}>
                <div style={{ display: "flex", marginLeft: "10px", width: "100%", alignItems: "center" }}>
                  <div style={{ width: "90%" }}>
                    {" "}
                    <Typography bold="true">{item.Process}</Typography>
                  </div>
                  <div style={{ width: "10%" }}>
                    {" "}
                    <Icon name="check-circle" size={24} color="black" className="eco-mr-8"></Icon>
                  </div>
                </div>
              </div>
            ) : item.Error === "No Error" ? (
              <div style={{ backgroundColor: "#B8E0BE", borderRadius: "5px", border: "1px solid #3D8F4B", width: "100%", display: "flex", padding: "20px", alignItems: "center", height: "50px", marginBottom: "10px" }} onClick={() => {}}>
                <div style={{ display: "flex", marginLeft: "10px", width: "100%", alignItems: "center" }}>
                  <div style={{ width: "90%" }}>
                    {" "}
                    <Typography bold="true">{item.Process}</Typography>
                  </div>
                  <div style={{ width: "10%" }}>
                    {" "}
                    <Icon name="check-circle" size={24} color="green" className="eco-mr-8"></Icon>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ backgroundColor: "#EBADAD", borderRadius: "5px", border: "1px solid #D65C5C", width: "100%", display: "flex", padding: "20px", alignItems: "center", height: "50px", marginBottom: "10px" }} onClick={(e) => handleErrorClick(e, item)}>
                  <div style={{ display: "flex", marginLeft: "10px", width: "100%", alignItems: "center" }}>
                    <div style={{ width: "90%" }}>
                      {" "}
                      <Typography bold="true">{item.Process}</Typography>
                    </div>
                    <div style={{ width: "10%" }}>
                      {" "}
                      <Icon name="close-circle" size={24} color="red" className="eco-mr-8"></Icon>
                    </div>
                  </div>
                </div>
                {item.ErrorMessage ? <Typography style={{ color: "#D65C5C", margin: "6px  0px 7px 0px" }}>* Error found in this section.</Typography> : null}
              </div>
            )
          )}
      </div>

      <div style={{ display: "flex", alignSelf: "flex-end", justifyContent: "space-around", marginBottom: "15px", width: proceedStatus ? "50%" : "20%" }}>
        {proceedStatus ? (
          <Button variant="primary" onClick={handleProceed}>
            Proceed{" "}
          </Button>
        ) : (
          <></>
        )}
        <Button variant={proceedStatus ? "secondary" : "primary"} onClick={() => setShowStatusPopup(false)}>
          Close{" "}
        </Button>
      </div>
    </div>
  );
}

export default OnboardingStatus;
