import React, { useState, useEffect } from "react";
import { Stack, StackItem, Button, Table, Space, InputGroup, SearchableMenu, RightPanelHeader, RightPanel, Accordion, Checkbox, Popover, ActionList, Icon, Alert, Typography, Popup, Steps, FileUploader, InlineMessage, Message, Label, DatePicker, Spin, ConfirmationPopup, Switch, Pagination, Select, InputBox, TextArea } from "witmeg-ui-system";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../routes/routeConstants";
import { getFormDetailsByModuleID, getFormDetailsByFormID, dynamicDataAccess } from "../../utilities/apiRequests/witmegDynamicServerRegistry";
import { useSelector } from "react-redux";
import axios from "axios";
import { Result } from "antd";
import navigatePage from "../../utilities/navigatePage.js";

function ViewApplication() {
  const navigate = useNavigate();
  const dashboardSliceState = useSelector((state) => state.dashboard);
  const tokenSliceState = useSelector((state) => state.tokenSlice);

  const [formID, setformID] = useState("");
  const [formDetails, setFormDetails] = useState("");
  const [apiData, setApiData] = useState({
    table: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
    button: {
      apiEndPoint: "",
      method: "",
      authType: "",
    },
  });

  const [tableData, setTableData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [formData, setFormData] = useState([]);
  const [nextPageData, setNextPageData] = useState();

  const handleShowApplicationOverview = (data) => {
    if (apiData.button.apiEndPoint !== "" && apiData.button.method !== "" && apiData.button.authType !== "") {
      const fetchData = async () => {
        const result = await dynamicDataAccess(
          {
            ID: "", //not necessary
            FormSubmissionNo: "", //not necessary
            TemplateFormDetails: {
              ID: formData.TemplateID, // this should be the TemplateID
              ApplicationID: formData.ApplicationID,
              FormID: formData.FormID,
              VersionNo: formData.Version,
              ActionType: apiData.button.actionType,
              EventType: apiData.button.eventType,
              WidgetID: apiData.button.widgetID,
              APIID: apiData.button.apiID,
            },
            PayLoad: [
              {
                Key: "OrganizationID",
                Value: data.organizationId,
                Type: "STRING",
              },
              {
                Key: "CloudLocationID",
                Value: data.cloudLocationId,
                Type: "STRING",
              },
            ],
            SubmittedBy: formData.CreatedBy,
            CreatedDate: formData.CreatedDate,
            ModifiedDate: formData.ModifiedDate,
            IsDeleted: false,
          },
          {
            method: apiData.button.method,
            url: apiData.button.apiEndPoint,
          }
        );

        if (Object.keys(result.Result).length > 0) {
          setNextPageData(result.Result);
          navigate(`${result.WorkFlow.HyperLink}`, { state: result });
        }
      };
      fetchData();
    }
  };

  const applicationTableColumns = [
    {
      title: () => <div></div>,
      key: "rowSelector",
      width: "4%",
      render: (record) => (
        <Checkbox
        //onChange={(e) => onSelectChangeTableRow(e, record)}
        />
      ),
    },
    {
      title: "Company Name",
      key: "companyName",
      dataIndex: "companyName",
    },
    {
      title: "Address",
      key: "address",
      dataIndex: "address",
    },
    {
      title: "Application Date",
      key: "applicationDate",
      dataIndex: "applicationDate",
    },
    {
      title: "Application Reference",
      key: "applicationReference",
      dataIndex: "applicationReference",
    },
    {
      title: "Submission Status",
      key: "submissionStatus",
      dataIndex: "submissionStatus",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            category="icon"
            variant="link"
            children="eye"
            size="small"
            iconSize={16}
            onClick={() => {
              handleShowApplicationOverview(record);
            }}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const mainModule_id = localStorage.getItem("mainModuleID");
    const subModule_id = localStorage.getItem("subModuleID");
    if (dashboardSliceState && dashboardSliceState.currentlySelectedOrgId && mainModule_id && subModule_id) {
      const fetchData = async () => {
        const result = await getFormDetailsByModuleID({
          ApplicationID: process.env.REACT_APP_APPLICATION_ID,
          ModuleID: mainModule_id,
          SubModuleID: subModule_id,
        });

        if (result && result.length > 0) {
          setformID(result[0].FormID);
        } else {
          setformID("");
        }
      };
      fetchData();
    }
  }, [dashboardSliceState]);

  const getFormattedAPIData = (result) => {
    setFormData(result);
    const updatedTableData = {
      apiEndPoint: `${result.Widgets[1].Action.APIConfig.BasePath}${result.Widgets[1].Action.APIConfig.Route}`,
      method: `${result.Widgets[1].Action.APIConfig.APIMethod}`,
      authType: `${result.Widgets[1].Action.APIConfig.AuthType}`,
    };

    const updatedButtonData = {
      apiEndPoint: `${result.Widgets[0].Action.APIConfig.BasePath}${result.Widgets[0].Action.APIConfig.Route}`,
      method: `${result.Widgets[0].Action.APIConfig.APIMethod}`,
      authType: `${result.Widgets[0].Action.APIConfig.AuthType}`,
      actionType: `${result.Widgets[0].Action.ActionType}`,
      eventType: `${result.Widgets[0].Action.EventType}`,
      widgetID: `${result.Widgets[0].Id}`,
      apiID: `${result.Widgets[0].Action.APIConfig.APIID}`,
    };

    setApiData((prevState) => ({
      ...prevState,
      table: {
        ...updatedTableData,
      },
      button: {
        ...updatedButtonData,
      },
    }));
  };

  const getFormattedData = async (result) => {
    return await result.map((data, index) => {
      return {
        id: index,
        companyName: data.OrganizationName,
        address: `${data.Address.AddressLine1}  ${data.Address.AddressLine2} ${data.Address.StateProvinceRegion} ${data.Address.PostZipCode}`,
        applicationDate: data.ApplicationDate,
        applicationReference: data.RefID,
        submissionStatus: data.OnboardingStatus,
        organizationId: data.OrganizationID,
        cloudLocationId: data.CloudLocationID,
        ...data,
      };
    });
  };

  useEffect(() => {
    if (apiData.table.apiEndPoint !== "" && apiData.table.method !== "" && apiData.table.authType !== "") {
      const fetchData = async () => {
        const result = await dynamicDataAccess(
          {
            OrganizationID: dashboardSliceState.currentlySelectedOrgId,
          },
          {
            method: apiData.table.method,
            url: apiData.table.apiEndPoint,
          }
        );

        if (result.Result.length > 0) {
          const data = await getFormattedData(result.Result);
          setTableData(data);
        }
      };
      fetchData();
    }
  }, [apiData]);

  useEffect(() => {
    if (dashboardSliceState && dashboardSliceState.currentlySelectedOrgId && formID !== "") {
      const fetchData = async () => {
        const result = await getFormDetailsByFormID({
          ApplicationID: process.env.REACT_APP_APPLICATION_ID,
          FormID: formID,
        });
        if (result && Object.keys(result).length > 0) {
          await getFormattedAPIData(result);
        }
      };
      fetchData();
    }
  }, [formID]);

  return (
    <div className="application-page-content" style={{ padding: "20px" }}>
      <Table columns={applicationTableColumns} dataSource={tableData} loading={isLoadingData} sticky />
    </div>
  );
}

export default ViewApplication;
